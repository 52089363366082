import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgModel, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ApiService } from '../api.service'; // Import ApiService

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  otpSent: boolean = false;
  otpVerified: boolean = false;
  resetPassword: boolean = false;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  verificationCode: string = ''; // Use a string for the OTP input
  resendOtpCountdown: number = 120; // Timer set to 2 minutes (120 seconds)
  timer: any; // Variable to hold the timer
  isLoading = false; // Add this flag for loader
  passwordVisible: boolean = false;
  confirmPasswordVisible:boolean = false;
  otpError: string | null = null;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService, // Inject ApiService
    private snackBar: MatSnackBar // Inject MatSnackBar for notifications
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', [Validators.required]],
    },
    {
      validators: this.matchPasswords('new_password', 'confirm_password'),
    }
  );

    // Add custom validation to the new_password field
    this.forgotPasswordForm.get('new_password')?.setValidators([
      Validators.required,
      Validators.minLength(8),
      this.passwordValidator.bind(this), // Bind the context to access `this`
    ]);
  }

  ngOnInit(): void {
    // console.log('OTP Verified:', this.otpVerified);
    // console.log('OTP Sent:', this.otpSent);
  }

  goBack(): void {
    if (this.otpVerified) {
      this.otpVerified = false;
      this.router.navigate(['/login']);
    } else if (this.otpSent) {
      this.otpSent = false;
      // Optionally, stay on the current page or navigate to a different page
    } else {
      this.router.navigate(['/login']);
    }
  }

  handleSendOtp(): void {
    const emailControl = this.forgotPasswordForm.get('email');
  
    if (emailControl?.valid) {
      this.isLoading = true; // Start loading
      this.apiService.sendOtp(emailControl.value.toLowerCase()).subscribe({
        next: (response) => {
          this.isLoading = false; // Stop loading
          this.otpSent = true;
          this.startResendOtpTimer(); // Start the timer when OTP is sent
          this.successMessage = response.message;
          emailControl.setErrors(null); // Clear previous errors if any
          
                  // Clear the success message after 3 seconds
        setTimeout(() => {
          this.successMessage = null;
        }, 3000);
        },
        error: (error) => {
          this.isLoading = false; // Stop loading in case of error
          this.otpSent = false;
          const errorMessage = error.error.detail || 'Failed to send OTP. Please try again.';
          this.setTemporaryError(emailControl, { apiError: errorMessage });
        },
      });
    } else {
      emailControl?.markAsTouched(); // Mark as touched to show validation errors
      if (emailControl?.hasError('required')) {
        this.setTemporaryError(emailControl, { required: true });
      } else if (emailControl?.hasError('email')) {
        this.setTemporaryError(emailControl, { email: true });
      }
    }
  }
    
  private setTemporaryError(control: AbstractControl | null, error: ValidationErrors): void {
    if (!control) return;
  
    control.setErrors(error); // Set the error
    setTimeout(() => {
      control.setErrors(null); // Clear the error after 3 seconds
    }, 3000);
  }
  

  startResendOtpTimer(): void {
    this.resendOtpCountdown = 120; // Reset timer to 2 minutes
    this.timer = setInterval(() => {
      if (this.resendOtpCountdown > 0) {
        this.resendOtpCountdown--;
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
  }

  handleVerifyOtp(): void {
    const email = this.forgotPasswordForm.get('email')?.value.toLowerCase();
    const otp = this.verificationCode;
  
    // Clear any previous messages
    this.successMessage = null;
    this.otpError = null;
  
    // Check if OTP length is 6
    if (!otp || otp.length !== 6) {
      this.otpError = 'OTP must be exactly 6 digits.';
      this.clearOtpErrorAfterDelay(); // Clear the error after 3 seconds
      return; // Stop execution if OTP is invalid
    }
  
    if (email) {
      this.apiService.verifyOtp(email, otp).subscribe({
        next: (response) => {
          this.successMessage = response.message;
          this.otpError = null;
          this.otpVerified = true;
          this.resetPassword = true;
          this.openSnackBar('Verified successfully', 'custom-snackbar-success');
        },
        error: (error) => {
          this.otpError = error.error?.detail || 'Invalid OTP. Please try again.';
          this.clearOtpErrorAfterDelay(); // Clear the error after 3 seconds
        },
      });
    } else {
      this.otpError = 'Invalid OTP or email. Please check and try again.';
      this.clearOtpErrorAfterDelay(); // Clear the error after 3 seconds
    }
  }
  
  // Utility function to clear otpError after 3 seconds
  private clearOtpErrorAfterDelay(): void {
    setTimeout(() => {
      this.otpError = null;
    }, 3000);
  }  

  handleResetPassword(): void {
    const email = this.forgotPasswordForm.get('email')?.value.toLowerCase();
    const otp = this.verificationCode;
    const new_password = this.forgotPasswordForm.get('new_password')?.value;
    const confirm_password = this.forgotPasswordForm.get('confirm_password')?.value;

      // Check if the passwords match
    if (new_password !== confirm_password) {
      this.forgotPasswordForm.get('confirm_password')?.setErrors({ mismatch: true });
      this.errorMessage = 'Passwords do not match.';
      this.clearPasswordErrorAfterDelay();
      return; // Stop further execution
    }
  
    if (new_password && new_password.length >= 8 && this.forgotPasswordForm.valid && otp.length === 6 && email) {
      this.apiService.resetPassword(email, otp, new_password).subscribe({
        next: (response) => {
          this.successMessage = response.message;
          this.errorMessage = null;
          this.openSnackBar(response.message, 'custom-snackbar-success');
          this.router.navigate(['/login']);
        },
        error: (error) => {
          const errorMessage = error.error.detail || 'Failed to reset password. Please try again.';
          this.successMessage = null;
          // Set the error on the form directly
          this.forgotPasswordForm.get('new_password')?.setErrors({ general: errorMessage });
          this.errorMessage = errorMessage;
  
          // Clear the error message after 3 seconds
          this.clearPasswordErrorAfterDelay();
        },
      });
    }else {
      const errorMessage = 'Invalid input. Please check your information and try again.';
      this.successMessage = null;
      this.forgotPasswordForm.get('new_password')?.setErrors({ general: errorMessage });
  
      this.clearPasswordErrorAfterDelay();
    }
  }

// Custom validator to check if passwords match
matchPasswords(password: string, confirmPassword: string) {
  return (group: AbstractControl): ValidationErrors | null => {
    const passwordControl = group.get(password);
    const confirmPasswordControl = group.get(confirmPassword);

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ mismatch: true });
      this.clearPasswordErrorAfterDelay();
      return { mismatch: true };
    } else {
      confirmPasswordControl.setErrors(null);
      return null;
    }
  };
}
  
  // Utility function to clear password error after 3 seconds
  private clearPasswordErrorAfterDelay(): void {
    setTimeout(() => {
      const newPasswordControl = this.forgotPasswordForm.get('new_password');
      const confirmPasswordControl = this.forgotPasswordForm.get('confirm_password');
      
      // Clear new_password errors
      if (newPasswordControl) {
        const errors = newPasswordControl.errors || {};
        delete errors['general']; // Remove the general error only
        if (Object.keys(errors).length === 0) {
          newPasswordControl.setErrors(null); // Clear all errors if none remain
        } else {
          newPasswordControl.setErrors(errors); // Retain other errors
        }
      }
  
      // Clear confirm_password errors
      if (confirmPasswordControl) {
        const errors = confirmPasswordControl.errors || {};
        delete errors['mismatch']; // Remove mismatch error only
        if (Object.keys(errors).length === 0) {
          confirmPasswordControl.setErrors(null); // Clear all errors if none remain
        } else {
          confirmPasswordControl.setErrors(errors); // Retain other errors
        }
      }
  
      this.errorMessage = null;
    }, 5000);
  }  
  
  passwordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.value || '';
    const errors: { [key: string]: boolean } = {};
  
    if (!password) {
      errors['required'] = true; // Required error
    } else {
      const isValid = 
        password.length >= 8 &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /\d/.test(password) &&
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password); // Special character check
  
      if (!isValid) {
        errors['general'] = true; // General validation error
      }
    }
  
    return Object.keys(errors).length > 0 ? errors : null;
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    config.verticalPosition = 'top'; // Position at the top
    config.horizontalPosition = 'center'; // Position at the center horizontally
  
    this.snackBar.open(message, 'Close', config);
  }
  
}
