<body class="dark:bg-white">
  <app-above-header></app-above-header>
  <app-header></app-header>

  <div class="px-5 mt-16 xl:px-16">
    <h1 class="text-3xl font-normal text-center sm:text-4xl lg:text-5xl 2xl:text-custom-font-65 2xl:leading-custom-line-73 text-custom-gray">
      <span class="mr-3 font-times lg:mr-4">Our</span>
      <span class="mr-3 italic font-times lg:mr-4">juice</span>
      <span class="block font-times sm:inline">options</span>
    </h1>
    <h1 class="font-poppins text-sm xl:text-lg 3xl:text-xl font-extrabold text-custom-gray flex items-center justify-center mt-6 tracking-[2px] 3xl:tracking-[4px]">
      DIVE INTO A WORLD OF FRESH, FLAVORFUL OPTIONS.
    </h1>

    <div class="flex flex-wrap mt-16">
      <!-- For md screen size -->
      <ng-container *ngIf="!isLgScreen">
        <div *ngFor="let product of products; let i = index" class="w-full md:w-1/2">
          <div
            class="flex flex-col items-center justify-between h-full pb-6 text-center"
            [ngClass]="{ 'md:border-r border-[#707070]': i % 2 === 0 }"
          >
            <!-- Shared Content -->
            <div class="flex items-center justify-center w-full px-5 mb-5">
              <!-- Image with Hover Effect -->
              <div class="relative w-full aspect-[16/14] group">
                <img
                  class="absolute inset-0 object-cover w-full h-full"
                  [src]="product.product_images.length > 0 ? product.product_images[0].image_url : '../../assets/images/juice-bottle.png'"
                  alt="Juice Bottle"
                  loading="lazy"
                />
                <!-- Overlay -->
                <div
                  class="absolute px-3 inset-0 flex flex-col text-left text-white transition-opacity text-sm xl:text-lg 2xl:text-custom-font-20 2xl:leading-custom-line-30 3xl:text-custom-font-22 3xl:leading-custom-line-50 font-poppins duration-300 bg-[#909090] opacity-0 bg-opacity-70 group-hover:opacity-90"
                >
                  <p class="px-2 py-3 3xl:py-8 3xl:px-12">
                    {{ product.product_description }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Headings -->
            <div class="flex flex-col items-center mt-5 text-center">
              <h1 class="text-3xl lg:text-[30px] 2xl:text-[35px] 2xl:leading-custom-line-60 font-times text-custom-gray uppercase">
                {{ product.product_name }}
              </h1>
              <h2
                class="mb-10 text-sm font-extrabold tracking-widest uppercase xl:text-lg 3xl:text-custom-font-20 lg:leading-custom-line-30 font-poppins text-custom-gray"
              >
                {{ product.juice_name }}
              </h2>
            </div>
            <!-- Button -->
            <button
              class="mt-2 border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-poppins text-sm 3xl:text-lg w-64 lg:w-60 2xl:w-72 3xl:w-80"
              (click)="handleSubscription(product)"
            >
              START YOUR SUBSCRIPTION
            </button>
          </div>
        </div>
      </ng-container>
    
      <!-- For lg screen size -->
      <ng-container *ngIf="isLgScreen">
        <div *ngFor="let product of products; let i = index" class="w-full lg:w-1/3">
          <div
            class="flex flex-col items-center justify-between h-full pb-6 text-center"
            [ngClass]="{ 'lg:border-r border-[#707070]': (i + 1) % 3 !== 0 }"
          >
            <!-- Shared Content -->
            <div class="flex items-center justify-center w-full px-5 mb-5">
              <!-- Image with Hover Effect -->
              <div class="relative w-full aspect-[16/14] group">
                <img
                  class="absolute inset-0 object-cover w-full h-full"
                  [src]="product.product_images.length > 0 ? product.product_images[0].image_url : '../../assets/images/juice-bottle.png'"
                  alt="Juice Bottle"
                  loading="lazy"
                />
                <!-- Overlay -->
                <div
                  class="absolute px-3 inset-0 flex flex-col text-left text-white transition-opacity text-sm xl:text-lg 2xl:text-custom-font-20 2xl:leading-custom-line-30 3xl:text-custom-font-22 3xl:leading-custom-line-50 font-poppins duration-300 bg-[#909090] opacity-0 bg-opacity-70 group-hover:opacity-90"
                >
                  <p class="px-2 py-3 3xl:py-8 3xl:px-12">
                    {{ product.product_description }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Headings -->
            <div class="flex flex-col items-center mt-5 text-center">
              <h1 class="text-3xl lg:text-[30px] 2xl:text-[35px] 2xl:leading-custom-line-60 font-times text-custom-gray uppercase">
                {{ product.product_name }}
              </h1>
              <h2
                class="mb-10 text-sm font-extrabold tracking-widest uppercase xl:text-lg 3xl:text-custom-font-20 lg:leading-custom-line-30 font-poppins text-custom-gray"
              >
                {{ product.juice_name }}
              </h2>
            </div>
            <!-- Button -->
            <button
              class="mt-2 border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-poppins text-sm 3xl:text-lg w-64 lg:w-60 2xl:w-72 3xl:w-80"
              (click)="handleSubscription(product)"
            >
              START YOUR SUBSCRIPTION
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    
    
    
  </div>
  <div class="h-40 lg:h-60">

  </div>
</body>
<div *ngIf="showPopup">
  <app-subscription-type-popup
    (close)="onPopupClose()"
    (subscriptionSelected)="onSubscriptionSelected($event)">
  </app-subscription-type-popup>
</div>
<app-footer></app-footer>