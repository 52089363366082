<!-- product.component.html -->
<body class="bg-white dark:overflow-y-scroll dark:h-screen">
  <app-sidebar [activeTab]="activeTab"></app-sidebar>
  
    <div class="lg:ml-64">
        <div class="p-4">

        <!-- Main container -->
          <div class="flex flex-col items-center justify-between w-full md:flex-row">
            
            <!-- Heading and Add button for screens smaller than md -->
            <div class="flex items-center justify-between w-full mb-4 md:w-1/3 md:mb-0">
              <h1 class="text-xl font-bold lg:text-2xl 2xl:text-4xl font-times text-custom-gray">All Product List</h1>
              <button
              (click)="togglePopup()" 
              class="flex items-center justify-center w-40 px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium 2xl:py-2 2xl:px-4 font-poppins lg:text-lg lg:w-60 md:hidden">
                <i class="mr-2 fa-solid fa-plus"></i> Add Product
              </button>
            </div>
            
            <!-- Search bar -->
            <div class="relative w-full mx-0 mb-2 md:w-1/3 md:mx-4 md:mb-0">
              <i class="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 fa-solid fa-magnifying-glass left-3 top-1/2"></i>
              <input 
                type="text" 
                placeholder="Search Products..." 
                [(ngModel)]="searchQuery" 
                (input)="filterItems()" 
                class="w-full py-1 pl-10 pr-4 transition duration-300 ease-in-out bg-white border border-gray-300 rounded-lg shadow-sm text-custom-gray lg:py-2 focus:outline-none focus:ring-1 focus:ring-custom-gold focus:border-transparent"
              />
            </div>
            
            <!-- Add Product button for screens larger than md -->
            <div class="flex items-center justify-center w-full md:w-1/3 md:justify-end">
              <button 
              (click)="togglePopup()"
              class="items-center justify-center hidden w-40 px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium 2xl:py-2 2xl:px-4 font-poppins lg:text-lg lg:w-60 md:flex">
                <i class="mr-2 fa-solid fa-plus"></i> Add Product
              </button>
            </div>
            
          </div>
          
          
            <!-- Table container -->
        <div class="overflow-scroll max-h-[50vh] lg:max-h-[45vh] xl:max-h-[60vh] 2xl:max-h-[70vh] 3xl:max-h-[85vh]">
          <table class="w-full mt-5 text-left table-auto min-w-max">
            <thead class="sticky top-0 bg-gray-50 h-fit">
              <tr>
                <th class="p-4 transition-colors border-gray-200 cursor-pointer border-y bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70">
                    Product Name
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
                <th class="p-4 transition-colors border-gray-200 cursor-pointer border-y bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70">
                    Juice Name
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
                <th class="p-4 transition-colors border-gray-200 cursor-pointer border-y bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70">
                    Product Description
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
                <th class="p-4 transition-colors border-gray-200 cursor-pointer border-y bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70">
                    Product Image
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
                <th class="p-4 transition-colors border-gray-200 cursor-pointer border-y bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70">
                    Quantity
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
                <th class="p-4 transition-colors border-gray-200 cursor-pointer border-y bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70">
                    Price
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
                <th class="p-4 transition-colors border-gray-200 cursor-pointer border-y bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70">
                    Actions
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- Loop through the products and display them -->
              <tr
              class="text-sm font-normal leading-normal transition-colors font-poppins text-custom-gray hover:bg-gray-100"
               *ngFor="let product of filteredItems; let i = index">
                <td class="p-4 uppercase border-b border-blue-gray-50">{{ product.product_name }}</td>
                <td class="p-4 uppercase border-b border-blue-gray-50">{{ product.juice_name }}</td>
                
                <td class="max-w-xl p-4 border-b border-blue-gray-50">
                  <div>
                    <!-- Check if the description length is greater than 100 -->
                    <span *ngIf="product.product_description && product.product_description.length > 80 && !expanded[i]">
                      {{ product.product_description | slice: 0:80 }}...
                      <button
                        class="text-blue-500 underline"
                        (click)="toggleDescription(i)"
                      >
                        Read More
                      </button>
                    </span>
                
                    <!-- Full description -->
                    <span *ngIf="expanded[i]">
                      {{ product.product_description }}
                      <button
                        class="text-blue-500 underline"
                        (click)="toggleDescription(i)"
                      >
                        Read Less
                      </button>
                    </span>
                
                    <!-- Display full description without Read More if less than or equal to 80 characters -->
                    <span *ngIf="product.product_description && product.product_description.length <= 80">
                      {{ product.product_description }}
                    </span>
                  </div>
                </td>
                
                <td class="p-4 border-b border-blue-gray-50">
                  <div class="flex flex-wrap gap-2">
                    <img
                      *ngFor="let img of product.product_images"
                      [src]="img.image_url"
                      alt="Product Image"
                      class="w-16 h-16 rounded-md"
                    />
                  </div>
                </td>
                
                <td class="p-4 border-b border-blue-gray-50">{{ product.quantity }} ML</td>
                <td class="p-4 border-b border-blue-gray-50">INR {{ product.price }}</td>
                <td class="p-4 border-b border-blue-gray-50">
                  <i class="mr-5 text-lg fa-solid fa-pen-to-square" (click)="editProduct(product)"></i>
                  <i class="text-lg fa-solid fa-trash" (click)="deleteProduct(product.product_master_id)"></i>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

                  <!-- Pagination -->
        <div class="flex items-center justify-end mt-4 space-x-1 text-sm">
          <button
            class="px-4 py-2 bg-gray-200 rounded-full hover:bg-gray-300"
            [disabled]="currentPage === 1"
            (click)="goToPage(currentPage - 1)"
          >
            <i class="fa-solid fa-chevron-left"></i>
          </button>
          <div class="flex gap-1">
            <button
              *ngFor="let page of pageNumbers"
              class="px-4 py-2 rounded-full"
              [class.bg-blue-500]="currentPage === page"
              [class.text-white]="currentPage === page"
              [class.bg-gray-200]="currentPage !== page"
              [class.text-black]="currentPage !== page"
              (click)="goToPage(page)"
            >
              {{ page }}
            </button>
          </div>
          <button
            class="px-4 py-2 bg-gray-200 rounded-full hover:bg-gray-300"
            [disabled]="currentPage === totalPages"
            (click)="goToPage(currentPage + 1)"
          >
          <i class="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
<!-- Product Popup Component -->
<app-product-popup 
  *ngIf="showPopup" 
  [product]="selectedProduct" 
  [editMode]="editMode" 
  (productUpdated)="handleProductUpdated($event)" 
  (closePopupEvent)="closePopup()">
</app-product-popup>

  </body>
  