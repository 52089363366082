import { Component, Input } from '@angular/core'; // Import Input decorator
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  @Input() activeTab: string = 'Dashboard'; // Declare activeTab as an input property

  isMenuOpen = false;
  userEmail: string | null = null; // Property to store user email
  userRole: string = ''; // Property to store user role
  isLoggedIn: boolean = false;

  constructor(private router: Router,    private apiService: ApiService,
    private snackBar: MatSnackBar,) {}

  ngOnInit() {
    // Retrieve user email from localStorage
    this.userEmail = localStorage.getItem('userEmail');
    // Check if the user is logged in
    this.isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    // Get session_role_id and map it to the corresponding role
    const sessionRoleId = localStorage.getItem('session_role_id');
    if (sessionRoleId) {
      this.userRole = this.getRoleById(sessionRoleId);
    }
  }

  // Function to map session_role_id to the corresponding role
  getRoleById(roleId: string): string {
    if (roleId === 'DR6OBr8M') {
      return 'Super Admin';
    } else if (roleId === 'm4I9bCyx') {
      return 'Admin';
    } else {
      return 'Anonymous Role'; // Default if roleId is not matched
    }
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  setActiveTab(tabName: string) {
    this.activeTab = tabName;
  }

  isActiveTab(tabName: string): boolean {
    return this.activeTab === tabName;
  }

  logout() {
    this.apiService.logout().subscribe({
      next: () => {
        this.router.navigate(['/login']);
        // this.openSnackBar('Logout successful!', 'custom-snackbar');
      },
      error: (error) => {
        // const errorMessage = error.error.detail || 'An error occurred during logout. Please try again!';
        // this.openSnackBar(errorMessage, 'custom-snackbar');
        localStorage.removeItem('session_user_id');
        localStorage.removeItem('session_role_id');
        localStorage.setItem('isLoggedIn', 'false'); // Update login state

      this.router.navigate(['/login']); // Navigate to login page
      }
    });
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    config.verticalPosition = 'top'; // Position at the top
    config.horizontalPosition = 'center'; // Position at the center horizontally
  
    this.snackBar.open(message, 'Close', config);
  }
  
  
}
