<body class="dark:bg-white">
<div class="overflow-hidden">
  <div class="flex flex-col min-h-screen bg-white-100">
    <app-header></app-header>
    <div class="flex items-center justify-center flex-grow">
      <div class="relative py-3 sm:mx-auto sm:max-w-xl">
        <div
          class="absolute inset-0 -skew-y-6 transform bg-[#CCD56A] shadow-lg sm:-rotate-6 sm:skew-y-0 sm:rounded-3xl"
        ></div>
        <div
          class="relative px-4 py-6 bg-white shadow-lg sm:rounded-3xl sm:p-12"
        >
          <div class="max-w-md mx-auto">
            <div class="text-center">


              <h1
                class="mb-4 text-4xl font-times dark:text-black"
                *ngIf="!otpVerified"
              >
                Forgot Password
              </h1>

              <h1
                class="mb-4 text-4xl font-times dark:text-black"
                *ngIf="otpVerified"
              >
                Reset Password
              </h1>

              <!-- Forgot Password Form -->
              <form
                *ngIf="!otpVerified"
                [formGroup]="forgotPasswordForm"
                class="divide-y divide-gray-200"
              >
                <div
                  class="py-3 space-y-4 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"
                >
                  <div class="relative">
                    <input
                      type="email"
                      formControlName="email"
                      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                      placeholder="Enter Email Address"
                      id="email"
                      required
                    />
                    <label
                      for="email"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      Email Address
                    </label>
                    <div
                    *ngIf="forgotPasswordForm.get('email')?.invalid && forgotPasswordForm.get('email')?.touched"
                    class="mt-1 text-sm text-left text-red-600"
                  >
                    <span *ngIf="forgotPasswordForm.get('email')?.hasError('required')">Email is required.</span>
                    <span *ngIf="forgotPasswordForm.get('email')?.hasError('email')">Invalid email address.</span>
                    <span *ngIf="forgotPasswordForm.get('email')?.hasError('apiError')">
                      {{ forgotPasswordForm.get('email')?.getError('apiError') }}
                    </span>
                  </div>
                  <div *ngIf="successMessage" class="mt-2 text-sm text-green-600">
                    {{ successMessage }}
                  </div>                  
                  
                  </div>
                  <div class="flex justify-center mt-2">
                    <button
                      type="button"
                      [disabled]="isLoading || otpSent"
                      (click)="handleSendOtp()"
                      [ngClass]="{
                        'cursor-not-allowed bg-gray-600': isLoading || otpSent,
                        'hover:bg-gray-900': !(isLoading || otpSent)
                      }"
                      class="flex items-center justify-center px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-times lg:text-lg w-fit"
                    >
                      <span
                        *ngIf="isLoading"
                        class="w-4 h-4 mr-2 border-t-2 border-white border-solid rounded-full animate-spin"
                        style="border-top-color: white"
                      ></span>
                      <span *ngIf="!isLoading">Send OTP</span>
                    </button>
                  </div>
                  <div *ngIf="!otpSent" class="flex items-center justify-center">
                    <button
                      (click)="goBack()"
                      class="flex items-center text-custom-gray hover:underline focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-6 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                        />
                      </svg>
                      Back to login
                    </button>
                  </div>
                  
                </div>
              </form>

              <!-- OTP Verification -->
              <div *ngIf="otpSent && !otpVerified" class="space-y-4">
                <div
                  class="py-8 space-y-6 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"
                >
                  <!-- OTP Input Fields -->
                  <div
                    class="relative flex items-center justify-center space-x-2"
                  >
                  <input
                  type="text"
                  [(ngModel)]="verificationCode"
                  class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                  placeholder="Enter 6 Digit OTP"
                  id="inputOtp"
                  required
                />
                <label
                  for="inputOtp"
                  class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                >
                Enter 6 Digit OTP
                </label>
                  </div>
                  <div *ngIf="otpError" class="text-sm text-left text-red-600">
                    {{ otpError }}
                  </div>
                  <div class="relative flex items-center justify-center">
                    <button
                      type="button"
                      (click)="handleVerifyOtp()"
                      class="px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-times lg:text-lg w-fit"
                    >
                      Verify OTP
                    </button>
                  </div>
                  <div class="relative flex items-center justify-center">
                    <!-- Resend OTP Section -->
                    <ng-container *ngIf="resendOtpCountdown > 0">
                      <span class="ml-5 text-gray-600"
                        >Resend OTP in {{ resendOtpCountdown }} seconds</span
                      >
                    </ng-container>
                    <ng-container *ngIf="resendOtpCountdown === 0">
                      <a
                        (click)="handleSendOtp()"
                        class="ml-5 cursor-pointer text-custom-gray hover:underline"
                      >
                        Resend OTP
                      </a>
                    </ng-container>
                  </div>
                </div>
              </div>

              <!-- Reset Password Form -->
              <form
                *ngIf="otpVerified"
                [formGroup]="forgotPasswordForm"
                class="divide-y divide-gray-200"
              >
                <div
                  class="py-3 space-y-4 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"
                >
                  <!-- New Password -->
                  <div class="relative">
                    <input
                      [type]="passwordVisible ? 'text' : 'password'"
                      formControlName="new_password"
                      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                      placeholder="New Password"
                      id="new_password"
                      required
                    />
                    <label
                      for="new_password"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      New Password
                    </label>
                    <div 
                    class="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      <i
                        [ngClass]="passwordVisible ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        class="text-gray-500 cursor-pointer"
                        (click)="passwordVisible = !passwordVisible"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div class="relative text-red-600">
                    <small
                      class="block max-w-xs text-left break-words"
                      *ngIf="forgotPasswordForm.get('new_password')?.touched && forgotPasswordForm.get('new_password')?.invalid"
                    >
                      <ng-container *ngIf="forgotPasswordForm.get('new_password')?.errors?.['required']">
                        New password is required.
                      </ng-container>
                      <ng-container *ngIf="!forgotPasswordForm.get('new_password')?.errors?.['required'] && forgotPasswordForm.get('new_password')?.errors?.['general']">
                        {{ errorMessage || 'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.' }}
                      </ng-container>
                    </small>
                  </div>

                  <!-- Confirm Password -->
                  <div class="relative">
                    <input
                      [type]="confirmPasswordVisible ? 'text' : 'password'"
                      formControlName="confirm_password"
                      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                      placeholder="Confirm Password"
                      id="confirm_password"
                      required
                    />
                    <label
                      for="confirm_password"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      Confirm Password
                    </label>
                    <div 
                    class="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      <i
                        [ngClass]="confirmPasswordVisible ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        class="text-gray-500 cursor-pointer"
                        (click)="confirmPasswordVisible = !confirmPasswordVisible"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div class="relative text-red-600">
                    <small
                      class="block max-w-xs text-left break-words"
                      *ngIf="
                        forgotPasswordForm.get('confirm_password')?.touched &&
                        forgotPasswordForm.get('confirm_password')?.errors
                      "
                    >
                      <ng-container *ngIf="forgotPasswordForm.get('confirm_password')?.errors?.['required']">
                        Confirm password is required.
                      </ng-container>
                      <ng-container *ngIf="forgotPasswordForm.get('confirm_password')?.errors?.['mismatch']">
                        Passwords do not match.
                      </ng-container>
                    </small>
                  </div>

                  <div class="flex justify-center mt-2">
                    <button
                      type="button"
                      (click)="handleResetPassword()"
                      class="px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-times lg:text-lg w-fit"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</body>