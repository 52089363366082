<!-- register component -->
<body class="dark:bg-white">
<div class="overflow-hidden">
  <div class="flex flex-col min-h-screen bg-white-100">
    <app-header></app-header>
    <div class="flex items-center justify-center flex-grow">
      <div class="relative py-3 sm:mx-auto sm:max-w-xl">
        <div
          class="absolute inset-0 -skew-y-6 transform bg-gradient-to-r bg-[#CCD56A] shadow-lg sm:-rotate-6 sm:skew-y-0 sm:rounded-3xl"
        ></div>
        <div
          class="relative px-4 py-6 bg-white shadow-lg sm:rounded-3xl sm:p-12"
        >
          <div class="max-w-md mx-auto">
            <div>
              <h1 class="text-4xl font-times dark:text-black">Register</h1>
            </div>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

              <!-- <div class="grid grid-cols-2 gap-6 py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"> -->
              <div
                class="gap-6 py-4 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"
              >
                <!-- Left Column -->
                <div class="space-y-4">

                  <!-- Email -->
                  <div class="relative">
                    <input
                    formControlName="email"
                      type="email"
                      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                      placeholder="Email Address"
                      id="email"
                      required
                      email
                    />
                    <label
                      for="email"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      Email Address
                    </label>
                    <div *ngIf="registerForm.get('email')?.invalid && registerForm.get('email')?.touched" class="mt-3">
                      <small class="text-red-600 " *ngIf="showError('email')">
                        {{ getErrorMessage('email') }}
                      </small>
                    </div>
                  </div>

                  <!-- Mobile Number -->
                  <div class="relative">
                    <input
                    formControlName="mobile"
                      name="mobile"
                      type="tel"
                      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                      placeholder="Mobile Number"
                      id="mobile"
                      required
                    />
                    <label
                      for="mobile"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      Mobile Number
                    </label>
                    <div *ngIf="registerForm.get('mobile')?.invalid && registerForm.get('mobile')?.touched" class="mt-3">
                      <small class="text-red-600 " *ngIf="showError('mobile')">
                        {{ getErrorMessage('mobile') }}
                      </small>
          
                    </div>
                  </div>

                  <!-- Password -->
                    <div class="relative">
                      <!-- Input Field -->
                      <input
                        formControlName="password"
                        [type]="passwordVisible ? 'text' : 'password'"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                        placeholder="Password"
                        (blur)="validatePassword()"
                        [ngClass]="{ 'is-invalid': password?.touched && password?.invalid }"
                        required
                      />
                      <!-- Label -->
                      <label
                        for="password"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        Password
                      </label>
                      <!-- Eye Icon -->
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                        <i
                          [ngClass]="passwordVisible ? 'fas fa-eye' : 'fas fa-eye-slash'"
                          class="text-gray-500 cursor-pointer"
                          (click)="passwordVisible = !passwordVisible"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    
                    <!-- Error Messages -->
                    <div class="relative left-0 max-w-[300px] text-red-600 top-full">
                      <small class="text-red-600 " *ngIf="showError('password')">
                        {{ getErrorMessage('password') }}
                      </small>
                    </div>
                  
                </div>

              <div *ngIf="formMessage" [ngClass]="{'text-green-600': formMessageType === 'success', 'text-red-600': formMessageType === 'error'}" class="mb-3 text-sm text-center">
                {{ formMessage }}
              </div>
              </div>

              <!-- Submit Button Centered -->
              <div class="flex justify-center">
                <button
                  type="submit"
                  [disabled]="isLoading"
                  [ngClass]="{
                    'cursor-not-allowed bg-gray-600': isLoading || otpSent,
                    'hover:bg-gray-900': !(isLoading || otpSent)
                  }"
                  class="flex items-center justify-center px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-times lg:text-lg w-fit"
                >
                  <!-- Conditional rendering of button content -->
                  <span
                    *ngIf="isLoading"
                    class="w-4 h-4 mr-2 border-t-2 border-white rounded-full loader animate-spin"
                  ></span>
                  <span *ngIf="!isLoading"
                    ><i class="mr-2 fas fa-check"></i> Submit</span
                  >
                </button>
              </div>

              <!-- OTP Input and Verify Button -->
  <div *ngIf="otpSent" class="mt-4">
    <div class="relative">
      <input
      formControlName="otp"
      type="text"
      maxlength="6"
      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times text-left"
      placeholder="Enter OTP"
      required
    />    
      <label
        for="otp"
        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
      >
        OTP
      </label>
    </div>
    <div class="flex justify-center py-4">
      <button
        type="button"
        (click)="onVerifyOtp()"
        class="px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-times lg:text-lg w-fit"
        >
        Verify OTP
      </button>
    </div>
  </div>
  
            </form>

            <div *ngIf="otpSent" class="relative flex items-center justify-center">
              <!-- Resend OTP Section -->
              <ng-container *ngIf="resendOtpCountdown > 0">
                <span class="ml-5 text-gray-600">Resend OTP in {{ resendOtpCountdown }} seconds</span>
              </ng-container>
              <ng-container *ngIf="resendOtpCountdown === 0">
                <a
                  (click)="handleSendOtp()"
                  class="ml-5 cursor-pointer text-custom-gray hover:underline"
                >
                  Resend OTP
                </a>
              </ng-container>
          </div>
          
            <div class="flex justify-center mt-2">
              <p class="text-lg font-times dark:text-custom-gray">
                Already have an account?
                <a routerLink="/login" class="text-blue-600 hover:underline">
                  Login here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</body>