import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AuthGuard } from './auth.guard';
import { CheckoutGuard } from './checkout.guard';
import { CartComponent } from './cart/cart.component';
import { ProductComponent } from './product/product.component';
import { CustomerComponent } from './customer/customer.component';
import { AdminSubscriptionComponent } from './admin-subscription/admin-subscription.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';
import { JuiceDeliveryComponent } from './juice-delivery/juice-delivery.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { JuiceOptionsComponent } from './juice-options/juice-options.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] }, // Default route
  { path: 'subscribe', component: SubscribeComponent, canActivate: [AuthGuard] },
  { path: 'subscription/:type', component: SubscriptionComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [AuthGuard] },
  { path: 'checkout', component: CheckoutComponent, canActivate: [CheckoutGuard] },
  { path: 'cart', component: CartComponent, canActivate: [AuthGuard] },
  { path :'product', component:ProductComponent, canActivate: [AuthGuard]},
  { path :'customer', component:CustomerComponent, canActivate: [AuthGuard]},
  { path :'admin-subscription', component:AdminSubscriptionComponent, canActivate: [AuthGuard]},
  { path :'T&C', component:TermsConditionsComponent, canActivate: [AuthGuard]},
  { path :'refund_policy', component:RefundPolicyComponent, canActivate: [AuthGuard]},
  { path :'privacy_policy', component:PrivacyPolicyComponent, canActivate: [AuthGuard]},
  { path :'return_policy', component:ReturnPolicyComponent, canActivate: [AuthGuard]},
  { path :'shipping_policy', component:ShippingPolicyComponent, canActivate: [AuthGuard]},
  { path :'juice-delivery', component:JuiceDeliveryComponent, canActivate: [AuthGuard]},
  { path :'about-us', component:AboutUsComponent, canActivate: [AuthGuard]},
  { path :'juice-options', component:JuiceOptionsComponent, canActivate: [AuthGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
