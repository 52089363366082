<body class="bg-white dark:overflow-y-scroll dark:h-screen">
  <app-sidebar [activeTab]="activeTab"></app-sidebar>

  <div class="lg:ml-64">
    <div class="p-4">
      <!-- Main container -->
      <div
        class="flex flex-col items-center justify-between w-full md:flex-row"
      >
        <!-- Heading and Add button for screens smaller than md -->
        <div
          class="flex items-center justify-between w-full mb-4 md:w-1/3 md:mb-0"
        >
          <h1
            class="text-xl font-bold lg:text-2xl 2xl:text-4xl font-times text-custom-gray"
          >
            All Customer List
          </h1>
        </div>

        <!-- Search bar -->
        <div class="relative w-full mx-0 mb-2 md:w-1/3 md:mx-4 md:mb-0">
          <i
            class="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 fa-solid fa-magnifying-glass left-3 top-1/2"
          ></i>
          <input
            type="text"
            placeholder="Search Customers..."
            class="w-full py-1 pl-10 pr-4 transition duration-300 ease-in-out bg-white border border-gray-300 rounded-lg shadow-sm text-custom-gray lg:py-2 focus:outline-none focus:ring-1 focus:ring-custom-gold focus:border-transparent"
            [(ngModel)]="searchTerm"
            (input)="filterUsers()"
          />
        </div>

        <div class="relative w-full mx-0 mb-2 md:w-1/3 md:mx-4 md:mb-0"></div>
      </div>
      <!-- Table container -->
      <div
        class="overflow-scroll max-h-[50vh] lg:max-h-[45vh] xl:max-h-[60vh] 2xl:max-h-[70vh] 3xl:max-h-[85vh]"
      >
        <table class="w-full mt-5 text-left table-auto min-w-max">
          <thead class="sticky top-0 bg-gray-50 h-fit">
            <tr>
              <th
                class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50"
              >
                <p
                  class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70"
                >
                  Customer Name
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    ></path>
                  </svg>
                </p>
              </th>
              <th
                class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50"
              >
                <p
                  class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70"
                >
                  Email
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    ></path>
                  </svg>
                </p>
              </th>
              <th
                class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50"
              >
                <p
                  class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70"
                >
                  Mobile Number
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    ></path>
                  </svg>
                </p>
              </th>
              <th
                class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50"
              >
                <p
                  class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70"
                >
                  Address
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    ></path>
                  </svg>
                </p>
              </th>
              <th
                class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50"
              >
                <p
                  class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70"
                >
                  Status
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    ></path>
                  </svg>
                </p>
              </th>
              <th
                *ngIf="sessionRoleId !== 'm4I9bCyx'"
                class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50"
              >
                <p
                  class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-custom-gray opacity-70"
                >
                  Actions
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Loop through the products and display them -->
            <tr
              *ngFor="let user of filteredUserList"
              class="text-sm font-normal leading-normal transition-colors font-poppins text-custom-gray hover:bg-gray-100"
            >
              <td class="p-4 border-b border-blue-gray-50">
                {{ getFullName(user) }}
              </td>
              <td class="p-4 border-b border-blue-gray-50">
                {{ user.email || "--" }}
              </td>
              <td class="p-4 border-b border-blue-gray-50">
                {{ user.mobile || "--" }}
              </td>
              <td class="p-4 border-b border-blue-gray-50">
                {{ getAddressDisplay(user) }}
              </td>
              <td class="p-4 border-b border-blue-gray-50">
                {{ user.is_active ? "Active" : "Inactive" }}
              </td>
              <td
                *ngIf="sessionRoleId !== 'm4I9bCyx'"
                class="p-4 border-b border-blue-gray-50"
              >
                <i
                  class="text-lg cursor-pointer fa-solid fa-trash"
                  (click)="deleteUser(user.user_id)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <div class="flex items-center justify-end mt-4 space-x-1 text-sm">
        <button
          class="px-4 py-2 bg-gray-200 rounded-full hover:bg-gray-300"
          [disabled]="currentPage === 1"
          (click)="goToPage(currentPage - 1)"
        >
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        <div class="flex gap-1">
          <button
            *ngFor="let page of pageNumbers"
            class="px-4 py-2 rounded-full"
            [class.bg-blue-500]="currentPage === page"
            [class.text-white]="currentPage === page"
            [class.bg-gray-200]="currentPage !== page"
            [class.text-black]="currentPage !== page"
            (click)="goToPage(page)"
          >
            {{ page }}
          </button>
        </div>
        <button
          class="px-4 py-2 bg-gray-200 rounded-full hover:bg-gray-300"
          [disabled]="currentPage === totalPages"
          (click)="goToPage(currentPage + 1)"
        >
          <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</body>
