<!DOCTYPE html>
<html>
  <head>
    <title>Checkout</title>
  </head>
  <body class="bg-white">
    <app-above-header></app-above-header>
    <app-header></app-header>
    <div class="container mx-auto p-4 max-w-[1200px]">
      <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <!-- Delivery Address Section -->
        <div class="col-span-2">
          <div class="p-4 border rounded-sm">
            <div
              class="grid items-start justify-between grid-cols-2 pb-4 mb-4 border-b-2 sm:grid-cols-2 sm:items-center border-custom-teal"
            >
              <h2
                class="items-center justify-center col-span-1 mb-2 text-2xl font-medium text-custom-gray font-times sm:mb-0 sm:col-span-1"
              >
                Delivery Addresses
              </h2>
              <div
                class="flex flex-col space-y-2 sm:flex-row sm:space-x-5 sm:space-y-0"
              >
                <button
                  (click)="openAddressModal()"
                  class="border-2 border-[#1D1A1A]  text-custom-gray  py-2 px-4 font-poppins text-sm md:text-lg w-full sm:w-auto transition duration-300 ease-in-out"
                >
                  Add New Address
                </button>
                <button
                  (click)="toggleAddressChange()"
                  class="border-2 border-[#1D1A1A]  text-custom-gray  py-2 px-4 font-poppins text-sm md:text-lg w-full sm:w-auto transition duration-300 ease-in-out"
                >
                  {{ isChangeAddressMode ? "Close" : "Change Address" }}
                </button>
              </div>
            </div>
            <!-- Display current address -->
            <div *ngIf="currentAddress && !isChangeAddressMode">
              <div class="flex items-center justify-between p-4 mb-4 font-poppins text-custom-gray">
                <div class="sm:ml-2">
                  <h3 class="text-lg font-medium sm:text-xl">
                    {{ currentAddress.first_name }}
                    {{ currentAddress.last_name }}
                  </h3>
                  <p class="text-sm sm:text-lg">
                    {{ currentAddress.address }}, {{ currentAddress.area }}
                  </p>
                  <p class="text-sm sm:text-lg">
                    {{ currentAddress.city }}, {{ currentAddress.state }} -
                    {{ currentAddress.zip_code }}
                  </p>
                </div>
                <div
                  class="flex flex-col gap-4 mt-2 sm:flex-row sm:items-center sm:mr-10 font-poppins"
                >
                  <button
                    (click)="openEditModal(currentAddress)"
                    class="flex items-center text-custom-gray hover:underline"
                  >
                    <i class="mr-2 text-lg fas fa-edit"></i> Edit
                  </button>
                  <button
                    *ngIf="addresses.length > 1"
                    (click)="deleteAddress(currentAddress.address_id)"
                    class="flex items-center text-custom-gray hover:underline"
                  >
                    <i class="mr-2 text-lg fas fa-trash-alt"></i> Delete
                  </button>
                </div>
              </div>
            </div>
            <!-- Display all addresses with radio buttons -->
            <div *ngIf="isChangeAddressMode">
              <div
                *ngFor="let address of addresses"
                class="flex items-center justify-between p-4 mb-4 rounded-lg shadow-md text-custom-gray font-poppins"
              >
                <div class="flex items-center ">
                  <input
                    type="radio"
                    name="selectedAddress"
                    [value]="address"
                    [(ngModel)]="selectedAddress"
                    class="mr-5 sm:mr-10"
                  />
                  <div>
                    <h3 class="text-lg font-medium sm:text-xl">
                      {{ address.first_name }} {{ address.last_name }}
                    </h3>
                    <p class="text-sm sm:text-lg">
                      {{ address.address }}, {{ address.area }}
                    </p>
                    <p class="text-sm sm:text-lg">
                      {{ address.city }}, {{ address.state }} -
                      {{ address.zip_code }}
                    </p>
                  </div>
                </div>
                <div
                  class="flex flex-col gap-4 mt-2 sm:flex-row sm:items-center sm:mr-10 font-poppins"
                >
                  <button
                    (click)="openEditModal(address)"
                    class="flex items-center text-custom-gray hover:underline"
                  >
                    <i class="mr-2 text-lg fas fa-edit"></i> Edit
                  </button>
                  <button
                    *ngIf="addresses.length > 1"
                    (click)="deleteAddress(address.address_id)"
                    class="flex items-center text-custom-gray hover:underline"
                  >
                    <i class="mr-2 text-lg fas fa-trash-alt"></i> Delete
                  </button>
                </div>
              </div>
              <div class="flex items-center justify-center">
                <button
                  (click)="useSelectedAddress()"
                  class="mb-4 border-2 border-[#1D1A1A]  text-custom-gray  py-2 px-4 font-poppins text-sm md:text-lg w-fit transition duration-300 ease-in-out"
                >
                  Use This Address
                </button>
              </div>
            </div>

            <div
              *ngIf="!addresses.length && !isChangeAddressMode"
              class="text-custom-gray"
            >
              No addresses found.
            </div>
          </div>

          <!-- Address Modal -->
          <div
            *ngIf="isModalOpen"
            class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 font-poppins"
          >
            <div
              class="relative w-full max-w-xl p-5 bg-white rounded-lg shadow-md sm:p-10"
            >
              <button
                (click)="closeAddressModal()"
                class="absolute top-2 right-2 text-custom-gray hover:text-gray-800 focus:outline-none"
              >
                <!-- Close icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <h2
                class="pb-2 mb-0 text-2xl font-medium border-b-2 text-custom-gray sm:mb-2 border-custom-teal sm:pb-4"
              >
                Add Address
              </h2>

              <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
                <div class="grid grid-cols-1 mt-3 sm:grid-cols-2 sm:gap-x-5 sm:mt-5">
                  <!-- First Name -->
                  <div class="relative mb-2 sm:mb-6">
                    <label
                      for="first_name"
                      class="block mb-1 ml-6 text-gray-700"
                      >First Name</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-user text-custom-gray"></i>
                      <input
                        id="first_name"
                        formControlName="first_name"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('first_name')?.invalid &&
                        addressForm.get('first_name')?.touched
                      "
                      class="text-red-600 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('first_name')?.errors?.['required']"
                      >
                        First name is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('first_name')?.errors?.['pattern']"
                      >
                        First name must contain only letters.
                      </div>
                    </div>
                  </div>

                  <!-- Last Name -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="last_name" class="block mb-1 ml-6 text-gray-700"
                      >Last Name</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-user text-custom-gray"></i>
                      <input
                        id="last_name"
                        formControlName="last_name"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('last_name')?.invalid &&
                        addressForm.get('last_name')?.touched
                      "
                      class="text-red-600 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('last_name')?.errors?.['required']"
                      >
                        Last name is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('last_name')?.errors?.['pattern']"
                      >
                        Last name must contain only letters.
                      </div>
                    </div>
                  </div>

                  <!-- Alternate Mobile Number -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="mobile" class="block mb-1 ml-6 text-gray-700"
                      >Mobile Number</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-phone text-custom-gray"></i>
                      <input
                        id="mobile"
                        formControlName="mobile"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('mobile')?.invalid &&
                        addressForm.get('mobile')?.touched
                      "
                      class="text-red-600 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('mobile')?.errors?.['required']"
                      >
                        Mobile number is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('mobile')?.errors?.['pattern']"
                      >
                        Mobile number must be 10 digits.
                      </div>
                    </div>
                  </div>

                  <!-- Address -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="address" class="block mb-1 ml-6 text-gray-700"
                      >Address</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-house text-custom-gray"></i>
                      <input
                        id="address"
                        formControlName="address"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('address')?.invalid &&
                        addressForm.get('address')?.touched
                      "
                      class="text-red-600 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('address')?.errors?.['required']"
                      >
                        Address is required.
                      </div>
                      <div *ngIf="addressForm.get('address')?.errors?.['maxlength']">
                        Address cannot exceed 200 characters.
                      </div>
                    </div>
                  </div>

                  <!-- Area -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="area" class="block mb-1 text-gray-700 ml-7"
                      >Area</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-house-user text-custom-gray"></i>
                      <input
                        id="area"
                        formControlName="area"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('area')?.invalid &&
                        addressForm.get('area')?.touched
                      "
                      class="text-red-600 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('area')?.errors?.['required']"
                      >
                        Area is required.
                      </div>
                      <div *ngIf="addressForm.get('area')?.errors?.['maxlength']">
                        Area cannot exceed 50 characters.
                      </div>                      
                    </div>
                  </div>

                  <!-- State -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="state" class="block mb-1 text-gray-700 ml-7"
                      >State</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-flag text-custom-gray"></i>
                      <input
                        id="state"
                        formControlName="state"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('state')?.invalid &&
                        addressForm.get('state')?.touched
                      "
                      class="text-red-600 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('state')?.errors?.['required']"
                      >
                        State is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('state')?.errors?.['pattern']"
                      >
                        State must contain only letters and spaces.
                      </div>
                      <div *ngIf="addressForm.get('state')?.errors?.['maxlength']">
                        State name cannot exceed 20 characters.
                      </div>                      
                    </div>
                  </div>

                  <!-- City -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="city" class="block mb-1 text-gray-700 ml-7"
                      >City</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-city text-custom-gray"></i>
                      <input
                        id="city"
                        formControlName="city"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('city')?.invalid &&
                        addressForm.get('city')?.touched
                      "
                      class="text-red-600 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('city')?.errors?.['required']"
                      >
                        City is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('city')?.errors?.['invalidCity']"
                      >
                        Delivery in Pune city only
                      </div>
                    </div>
                  </div>

                  <!-- Zip Code -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="zip_code" class="block mb-1 text-gray-700 ml-7"
                      >Zip Code</label
                    >
                    <div class="flex items-center">
                      <i
                        class="mr-4 fas fa-map-marker-alt text-custom-gray sm:mr-3"
                      ></i>
                      <input
                        id="zip_code"
                        formControlName="zip_code"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('zip_code')?.invalid &&
                        addressForm.get('zip_code')?.touched
                      "
                      class="text-red-600 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('zip_code')?.errors?.['required']"
                      >
                        Zip code is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('zip_code')?.errors?.['pattern']"
                      >
                        Zip code must be 5-6 digits.
                      </div>
                    </div>
                  </div>
                </div>

              <!-- Success Message -->
              <div *ngIf="successMessage" class="mb-3 text-sm text-center text-green-600">
                {{ successMessage }}
              </div>

              <!-- Error Message -->
              <div *ngIf="errorMessage" class="mb-3 text-sm text-center text-red-600 ">
                {{ errorMessage }}
              </div>
              
                <!-- Submit Button -->
                <div class="flex justify-center mt-2 sm:mt-6">
                  <button
                    type="submit"
                    class="px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-times lg:text-lg w-fit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- Edit Modal -->
          <div
            *ngIf="isEditModalOpen"
            class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 font-poppins"
          >
            <div
            class="relative w-full max-w-xl p-5 bg-white rounded-lg shadow-md sm:p-10"
            >
              <button
                (click)="closeEditModal()"
                class="absolute top-2 right-2 text-custom-gray hover:text-gray-800 focus:outline-none"
              >
                <!-- Close icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <h2
              class="pb-2 mb-0 text-2xl font-medium border-b-2 text-custom-gray sm:mb-2 border-custom-teal sm:pb-4"
              >
                Edit Address
              </h2>

              <form [formGroup]="addressForm" (ngSubmit)="editSubmit()">
                <div class="grid grid-cols-1 mt-3 sm:grid-cols-2 sm:gap-x-5 sm:mt-5">
                  <!-- First Name -->
                  <div class="relative mb-2 sm:mb-6">
                    <label
                      for="first_name"
                      class="block mb-1 ml-6 text-gray-700"
                      >First Name</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-user text-custom-gray"></i>
                      <input
                        id="first_name"
                        formControlName="first_name"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('first_name')?.invalid &&
                        addressForm.get('first_name')?.touched
                      "
                      class="text-red-600 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('first_name')?.errors?.['required']"
                      >
                        First name is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('first_name')?.errors?.['pattern']"
                      >
                        First name must contain only letters.
                      </div>
                    </div>
                  </div>

                  <!-- Last Name -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="last_name" class="block mb-1 ml-6 text-gray-700"
                      >Last Name</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-user text-custom-gray"></i>
                      <input
                        id="last_name"
                        formControlName="last_name"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('last_name')?.invalid &&
                        addressForm.get('last_name')?.touched
                      "
                      class="text-red-600 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('last_name')?.errors?.['required']"
                      >
                        Last name is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('last_name')?.errors?.['pattern']"
                      >
                        Last name must contain only letters.
                      </div>
                    </div>
                  </div>

                  <!-- Mobile Number -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="mobile" class="block mb-1 ml-6 text-gray-700"
                      >Mobile Number</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-phone text-custom-gray"></i>
                      <input
                        id="mobile"
                        formControlName="mobile"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('mobile')?.invalid &&
                        addressForm.get('mobile')?.touched
                      "
                      class="text-red-600 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('mobile')?.errors?.['required']"
                      >
                        Mobile number is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('mobile')?.errors?.['pattern']"
                      >
                        Mobile number must be 10 digits.
                      </div>
                    </div>
                  </div>

                  <!-- Address -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="address" class="block mb-1 ml-6 text-gray-700"
                      >Address</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-house text-custom-gray"></i>
                      <input
                        id="address"
                        formControlName="address"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div    
                      *ngIf="
                        addressForm.get('address')?.invalid &&
                        addressForm.get('address')?.touched
                      "
                      class="text-red-600 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('address')?.errors?.['required']"
                      >
                        Address is required.
                      </div>
                      <div *ngIf="addressForm.get('address')?.errors?.['maxlength']">
                        Address cannot exceed 200 characters.
                      </div>
                    </div>
                  </div>

                  <!-- Area -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="area" class="block mb-1 text-gray-700 ml-7"
                      >Area</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-house-user text-custom-gray"></i>
                      <input
                        id="area"
                        formControlName="area"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('area')?.invalid &&
                        addressForm.get('area')?.touched
                      "
                      class="text-red-600 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('area')?.errors?.['required']"
                      >
                        Area is required.
                      </div>
                      <div *ngIf="addressForm.get('area')?.errors?.['maxlength']">
                        Area cannot exceed 50 characters.
                      </div>                      
                    </div>
                  </div>

                  <!-- State -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="state" class="block mb-1 text-gray-700 ml-7"
                      >State</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-flag text-custom-gray"></i>
                      <input
                        id="state"
                        formControlName="state"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('state')?.invalid &&
                        addressForm.get('state')?.touched
                      "
                      class="text-red-600 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('state')?.errors?.['required']"
                      >
                        State is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('state')?.errors?.['pattern']"
                      >
                        State must contain only letters and spaces.
                      </div>
                      <div *ngIf="addressForm.get('state')?.errors?.['maxlength']">
                        State name cannot exceed 20 characters.
                      </div>                      
                    </div>
                  </div>

                  <!-- City -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="city" class="block mb-1 text-gray-700 ml-7"
                      >City</label
                    >
                    <div class="flex items-center">
                      <i class="mr-2 fas fa-city text-custom-gray"></i>
                      <input
                        id="city"
                        formControlName="city"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('city')?.invalid &&
                        addressForm.get('city')?.touched
                      "
                      class="text-red-600 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('city')?.errors?.['required']"
                      >
                        City is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('city')?.errors?.['invalidCity']"
                      >
                        Delivery in Pune city only
                      </div>
                    </div>
                  </div>

                  <!-- Zip Code -->
                  <div class="relative mb-2 sm:mb-6">
                    <label for="zip_code" class="block mb-1 text-gray-700 ml-7"
                      >Zip Code</label
                    >
                    <div class="flex items-center">
                      <i
                      class="mr-4 fas fa-map-marker-alt text-custom-gray sm:mr-3"
                      ></i>
                      <input
                        id="zip_code"
                        formControlName="zip_code"
                        class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('zip_code')?.invalid &&
                        addressForm.get('zip_code')?.touched
                      "
                      class="text-red-600 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('zip_code')?.errors?.['required']"
                      >
                        Zip code is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('zip_code')?.errors?.['pattern']"
                      >
                        Zip code must be 5 digits.
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Success Message -->
              <div *ngIf="successMessage" class="mb-3 text-sm text-center text-green-600">
                {{ successMessage }}
              </div>

              <!-- Error Message -->
              <div *ngIf="errorMessage" class="mb-3 text-sm text-center text-red-600 ">
                {{ errorMessage }}
              </div>

                <!-- Submit Button -->
                <div class="flex justify-center mt-2 sm:mt-6">
                  <button
                    type="submit"
                    class="px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-times lg:text-lg w-fit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- Payment Method Section -->
          <div class="p-4 mt-4 border rounded">
            <h2
              class="pb-4 mb-2 text-2xl font-medium border-b-2 text-custom-gray font-times border-custom-teal"
            >
              Payment Method
            </h2>
            <!-- Payment Form Goes Here -->
            <div
              class="flex items-center justify-between p-4 transition-colors border-b cursor-pointer hover:bg-gray-50 "
              (click)="togglePaymentMethod()"
            >
              <div class="flex items-center gap-2">
                <input
                  type="radio"
                  name="payment"
                  [(ngModel)]="selectedPayment"
                  [value]="'razorpay'"
                  id="razorpay-radio"
                  class="mr-2"
                  checked
                />
                <span class="text-xl font-medium text-custom-gray font-poppins">Cash On Delivery</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6 text-gray-500 transition-transform"
                [ngClass]="{ 'rotate-180': isRazorpaySelected }"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
            <div
              *ngIf="isRazorpaySelected"
              class="items-center p-4 mt-2 text-sm text-gray-600 sm:flex"
            >
              <!-- Image Column -->
              <!-- <div class="w-40 h-20 mr-4 razor-pay-image"></div> -->

              <!-- Text Column -->
              <p class="sm:flex-1 sm:text-left">
                Please pay the amount in cash upon delivery. Thank you for choosing our service!
              </p>
            </div>
          </div>
<!-- Review Items and Delivery Section -->
<div class="p-4 mt-4 border rounded">
  <h2 class="pb-4 mb-2 text-2xl font-medium border-b-2 text-custom-gray font-times border-custom-teal">
    Review Items and Delivery
  </h2>

  <!-- Render based on subscriptionData -->
  <div *ngIf="subscriptionData; else cartItemsSection" class="grid items-center justify-center grid-cols-1 gap-4 p-4 pb-2 mb-2 transition duration-300 rounded-lg sm:grid-cols-2 hover:bg-gray-50">
    <!-- Image Column -->
    <div class="flex items-start justify-center sm:justify-start">
      <div class="w-40 h-40 juice-bottle-image"></div>
    </div>

    <!-- Product Details Column -->
    <div class="font-poppins">
      <h3 class="mb-1 text-xl font-medium text-center sm:text-left text-custom-gray">
        {{ subscriptionData.product_name }}
      </h3>
      <p class="mb-2 text-lg text-gray-600">
        {{
          subscriptionData.subscription_type === "daily"
            ? "Daily Subscription"
            : subscriptionData.subscription_type === "alternate-day"
            ? "Alternate Day Subscription"
            : subscriptionData.subscription_type === "weekly"
            ? "Weekly Subscription"
            : "Unknown Subscription Type"
        }}
        | {{ subscriptionData.quantity }} ML
      </p>
      <p class="mb-2 text-lg text-gray-600 sm:text-nowrap">
        Delivery From : {{ subscriptionData.start_date | date : "d MMMM yyyy hh:mm a" }}
      </p>
      <p class="text-xl font-bold text-red-600">
        ₹{{ subscriptionData.price }}
      </p>
    </div>
  </div>

  <!-- Fallback for selectedCartItems -->
  <ng-template #cartItemsSection>
    <div *ngIf="selectedCartItems.length" class="grid items-center justify-center grid-cols-1 gap-4 p-4 pb-2 mb-2 transition duration-300 rounded-lg sm:grid-cols-2 hover:bg-gray-50 font-poppins">
      <ng-container *ngFor="let item of selectedCartItems">
        <!-- Image Column -->
        <div class="flex items-start justify-center sm:justify-start">
          <div class="w-40 h-40 juice-bottle-image"></div>
        </div>

        <!-- Product Details Column -->
        <div>
          <h3 class="mb-1 text-xl font-medium text-center text-gray-800 sm:text-left">
            {{ item.product_name }}
          </h3>
          <p class="mb-2 text-lg text-gray-600">
            {{
              item.subscription_type === "daily"
                ? "Daily Subscription"
                : item.subscription_type === "alternate-day"
                ? "Alternate Day Subscription"
                : item.subscription_type === "weekly"
                ? "Weekly Subscription"
                : "Unknown Subscription Type"
            }}
            | {{ item.quantity }} ML
          </p>
          <p class="mb-2 text-lg text-gray-600 sm:text-nowrap">
            Delivery From : {{ item.selected_date | date : "d MMMM yyyy hh:mm a" }}
          </p>
          <p class="text-xl font-bold text-red-600">
            ₹{{ item.price }}
          </p>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
</div>
<!-- Order Summary Section -->
<div class="col-span-1">
  <div class="p-5 border rounded">
    <div class="flex items-center justify-between mb-4 border-b-2 border-custom-teal">
      <h2 class="mb-4 text-2xl font-medium text-custom-gray font-times">Order Summary</h2>
      <button
      (click)="placeOrder()"
        class="px-2 py-1 mb-4 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-poppins lg:text-lg w-fit"
      >
        Place Order
      </button>
    </div>

    <!-- Summary Details -->
    <ng-container *ngIf="subscriptionData">
      <div class="font-poppins">
      <div class="flex items-center justify-between mb-3">
        <span class="text-gray-700 ">Items:</span>
        <span class="font-medium text-gray-900 ">₹{{ subscriptionData.price }}</span>
      </div>
      <div class="flex items-center justify-between mb-3">
        <span class="text-gray-700 ">Delivery:</span>
        <span class="font-medium text-gray-900 ">Free</span>
      </div>
      <div class="flex items-center justify-between mb-3">
        <span class="text-gray-700 ">Total:</span>
        <span class="font-medium text-gray-900 ">₹{{ subscriptionData.price }}</span>
      </div>
    </div>
    </ng-container>

    <ng-container *ngIf="!subscriptionData && selectedCartItems.length">
      <div class="font-poppins">
      <div class="flex items-center justify-between mb-3">
        <span class="text-gray-700 ">Items:</span>
        <span class="font-medium text-gray-900 ">₹{{ totalCartPrice }}</span>
      </div>
      <div class="flex items-center justify-between mb-3">
        <span class="text-gray-700 ">Delivery:</span>
        <span class="font-medium text-gray-900 ">Free</span>
      </div>
      <div class="flex items-center justify-between mb-3">
        <span class="text-gray-700 ">Total:</span>
        <span class="font-medium text-gray-900 ">₹{{ totalCartPrice }}</span>
      </div>
      </div>
    </ng-container>

    <hr class="my-4" />
    <div class="flex items-center justify-between text-xl font-extrabold text-red-600 font-poppins">
      <span>Order Total:</span>
      <span>₹{{ subscriptionData?.price || totalCartPrice }}</span>
    </div>
  </div>
</div>


      </div>
    </div>
  </body>
    <app-footer></app-footer>

</html>
