
<!-- header.html -->
<nav class="fixed top-0 z-20 w-full bg-white border-b border-gray-500 dark:bg-white">
  <div class="w-full min-w-[100%] mx-auto flex flex-col lg:flex-row items-center justify-between h-32 xl:p-14">
    
    <!-- Links, Logo, and Icons Container -->
    <div class="flex items-center justify-between w-full">
      <!-- Logo - Always Visible -->
      <a class="flex items-center justify-center w-full logo-link lg:justify-start lg:w-1/3">
        <div class="w-full flex-shrink-0 flex justify-center items-center 2xl:justify-start h-[80px] md:h-[80px] lg:h-[57px] md:w-[600px] lg:w-[650px] ml-[10px] lg:ml-[-80px]">
          <img class="object-cover w-full h-full" src="/assets/images/Nirdhara Website Icons-03.png" alt="Logo img" />
        </div>
      </a>

      <!-- User Email and Role Section - Hidden when Burger Menu is Open -->
      <div 
        *ngIf="isLoggedIn && userEmail" 
        class="flex-col items-end justify-end hidden px-2 space-y-1 lg:flex"
      >
        <!-- User Email -->
        <div class="text-lg font-medium text-gray-900 font-poppins">
          Hi, {{ userEmail }}
        </div>
        
        <!-- Role -->
        <div *ngIf="userRole" 
          class="flex items-center px-4 py-1 text-sm font-medium text-white rounded-tl-full rounded-bl-full rounded-br-full bg-custom-gray font-poppins">
          <i class="mr-2 fas fa-user-shield"></i>
          {{ userRole }}
        </div>
      </div>
    </div>

    <!-- Burger Menu and Icons -->
    <div class="relative flex items-center justify-between w-full mb-5 lg:hidden">
      <!-- Burger Menu Button -->
      <button (click)="toggleMenu()" class="mt-5 text-black">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>
    
      <!-- User Email and Role - Visible next to the burger menu -->
      <div *ngIf="isLoggedIn && userEmail" class="flex flex-col items-end justify-end px-2">
        <!-- User Email -->
        <div class="text-sm font-medium text-gray-900 font-poppins">
          Hi, {{ userEmail }}
        </div>

        <!-- Role -->
        <div *ngIf="userRole" 
          class="flex px-4 py-1 text-xs font-medium text-white rounded-tl-full rounded-bl-full rounded-br-full w-fit bg-custom-gray font-poppins">
          <i class="mr-2 fas fa-user-shield"></i>
          {{ userRole }}
        </div>
      </div>
    </div>
    
  </div>
</nav>



<!-- Sidebar Container -->
  <div class="flex flex-col mt-[129px] font-poppins">


      <div id="Main" [ngClass]="{'translate-x-0': isMenuOpen, '-translate-x-full': !isMenuOpen}"
      class="fixed transform lg:translate-x-0 ease-in-out transition duration-500 z-30 flex justify-start items-start h-[calc(100vh-105px)]
       lg:h-[calc(100vh-129px)] lg:mt-0 w-full  lg:w-64 bg-custom-teal flex-col">
    

       <div class="flex flex-col items-center justify-start w-full px-4 pb-5 space-y-6">
        <!-- Dashboard Tab -->
        <div class="hidden w-full">
          <a
            href="/admin-dashboard"
            (click)="setActiveTab('Dashboard')"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Dashboard' }"
          >
            <div
              class="flex items-center justify-center w-8 h-8 text-center rounded-lg bg-custom-gold"
            >
              <i class="text-base text-white fas fa-home"></i>
            </div>
            <span class="ml-3">Dashboard</span>
          </a>
        </div>
      
        <!-- Customer Tab -->
        <div class="w-full">
          <a
            href="/customer"
            (click)="setActiveTab('Customer')"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Customer' }"
          >
            <div
              class="flex items-center justify-center w-8 h-8 text-center rounded-lg bg-custom-gold"
            >
              <i class="text-base text-white fas fa-user"></i>
            </div>
            <span class="ml-3">Customer</span>
          </a>
        </div>
      
        <!-- Product Tab -->
        <div class="w-full">
          <a
            href="/product"
            (click)="setActiveTab('Product')"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Product' }"
          >
            <div
              class="flex items-center justify-center w-8 h-8 text-center rounded-lg bg-custom-gold"
            >
              <i class="text-base text-white fas fa-truck"></i>
            </div>
            <span class="ml-3">Product</span>
          </a>
        </div>
      
        <!-- Subscription Tab -->
        <div class="w-full">
          <a
            href="/admin-subscription"
            (click)="setActiveTab('Subscription')"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Subscription' }"
          >
            <div
              class="flex items-center justify-center w-8 h-8 text-center rounded-lg bg-custom-gold"
            >
              <i class="text-base text-white fas fa-calendar-days"></i>
            </div>
            <span class="ml-3">Subscription</span>
          </a>
        </div>

        <div class="w-full">
          <!-- Logout Button -->
          <a
            (click)="logout()"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Logout' }"
          >
            <div
              class="flex items-center justify-center w-8 h-8 text-center rounded-lg bg-custom-gold"
            >
              <i class="text-base text-white fas fa-power-off"></i>
            </div>
            <span class="ml-3">Logout</span>
          </a>
        </div>

      </div>
      
</div>
</div>
