<!-- subscription-type-popup.component.html -->
<div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="relative max-w-2xl p-4 bg-white shadow-xl rounded-xl sm:p-8 sm:max-w-xl">
      <!-- Close Button (Icon) -->
      <button (click)="onClose()" class="absolute text-gray-500 transition duration-300 ease-in-out top-2 right-2 hover:text-gray-800">
        <!-- Close Icon -->
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-7 h-7">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
  
      <!-- Popup Content -->
      <h1 class="mb-1 text-xl font-bold text-center text-gray-800 sm:text-2xl font-times">Customize Your Plan</h1>
      <p class="pb-4 mb-6 text-sm text-center text-gray-800 border-b-2 sm:text-lg font-times border-custom-teal">
        Choose the subscription plan that fits your needs.
      </p>
  
      <!-- Dropdown -->
      <div class="flex justify-center mb-6">
        <select 
          [(ngModel)]="subscriptionType" 
          (change)="onSelect(subscriptionType)" 
          class="border-2 border-[#1D1A1A] bg-white text-custom-gray py-2 px-4 font-poppins text-xs sm:text-lg w-64">
          <option value="" disabled selected>Select Subscription Plan</option>
          <option value="daily">Daily Subscription</option>
          <option value="weekly">Weekly Subscription</option>
          <option value="alternate-day">Alternate Day Subscription</option>
        </select>
      </div>
  
    </div>
  </div>
  