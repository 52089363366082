import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-subscription-type-popup',
  templateUrl: './subscription-type-popup.component.html',
  styleUrls: ['./subscription-type-popup.component.css']
})
export class SubscriptionTypePopupComponent {
  @Output() close = new EventEmitter<void>(); // Emit event when popup is closed
  @Output() subscriptionSelected = new EventEmitter<string>(); // Emit selected subscription type

  subscriptionType: string = ''; // Selected subscription type

  onClose(): void {
    this.close.emit(); // Close popup
  }

  onSelect(type: string): void {
    if (type) {
      this.subscriptionSelected.emit(type); // Emit selected type
      this.onClose(); // Close the popup after selection
    }
  }
}
