<!-- terms-conditions.component.html -->
<body class="dark:bg-white">
    <app-above-header></app-above-header>
    <app-header></app-header>
  
<!-- Main Content Section -->
<main class="flex flex-col">

  <!-- Page Title -->
  <h1 class="mt-64 text-3xl font-normal text-center sm:text-4xl lg:text-5xl 2xl:text-custom-font-65 2xl:leading-custom-line-73 text-custom-gray">
    <span class="mr-3 font-times lg:mr-4">Our</span>
    <span class="mr-3 italic font-times lg:mr-4">Terms</span>
    <span class="block font-times sm:inline">And Conditions</span>
  </h1>
<div class="px-10 ml-5 lg:px-20 2xl:px-36">
  <!-- Subtitle -->
  <h1 class="font-poppins text-sm xl:text-lg 3xl:text-xl font-bold text-custom-gray flex mt-8 tracking-[2px] 3xl:tracking-[4px]">
    BY SUBSCRIBING TO OUR FRESH JUICE DELIVERY SERVICE, YOU AGREE TO THE FOLLOWING TERMS AND CONDITIONS:
  </h1>

  <!-- Terms Content -->
  <section class="max-w-6xl w-full text-left text-[#2F2C2B] mt-28 space-y-6">
      <!-- Subscription Agreement -->
      <h2 class="section-title">SUBSCRIPTION AGREEMENT</h2>
      <ol class="ordered-list">
          <li class="max-w-[95ch] break-words">
              By placing an order, you agree to receive regular deliveries of our selected juices based on your subscription plan.
          </li>
          <li class="max-w-[90ch] break-words">
            Subscription options include weekly, bi-weekly, and monthly deliveries. You can modify or cancel your
            subscription at any time, subject to our cancellation policy.
          </li>                   
      </ol>

      <!-- Payment -->
      <h2 class="section-title">PAYMENT</h2>
      <ol class="ordered-list">
        <li class="max-w-[95ch] break-words">
          Payments are processed automatically according to the subscription cycle selected. We accept major credit and
          debit cards.
        </li>        
        <li>
          Prices are subject to change, but you will be notified in advance of any adjustments that affect your subscription.
        </li>
    </ol>

    <!-- Delivery -->
    <h2 class="section-title">DELIVERY</h2>
    <ol class="ordered-list">
      <li class="max-w-[95ch] break-words">
        We deliver to specified areas. Please ensure your delivery address is accurate and accessible. Nirdhara is not responsible for delivery failures due to incorrect or incomplete addresses.
      </li>        
      <li>
        If you are unavailable to receive the delivery, please provide instructions on where to leave the package securely.
      </li>
    </ol>

  <!-- PRODUCT FRESHNESS AND QUALITY -->
  <h2 class="section-title">PRODUCT FRESHNESS AND QUALITY</h2>
  <ol class="ordered-list">
    <li class="max-w-[95ch] break-words">
      We guarantee the freshness and quality of our juices. If you receive a damaged or substandard product, please contact us within 24 hours for a replacement or refund.
    </li>        
  </ol>

    <!-- 5MODIFICATIONS AND CANCELLATIONS -->
    <h2 class="section-title">MODIFICATIONS AND CANCELLATIONS</h2>
    <ol class="ordered-list">
      <li class="max-w-[92ch] break-words">
        You may modify your subscription or cancel at any time through your account settings. Changes must be made at least 48 hours before your next scheduled delivery.     
     </li>        
      <li>
        Cancellations made after the cutoff time may be subject to a fee.
      </li>
    </ol>

    <!-- REFUNDS AND RETURNS -->
    <h2 class="section-title">REFUNDS AND RETURNS</h2>
    <ol class="ordered-list">
      <li class="max-w-[92ch] break-words">
        No Refunds
      </li>
      <li class="max-w-[92ch] break-words">
        ⁠No Returns
      </li>
    </ol>

    <!-- PRIVACY POLICY -->
    <h2 class="section-title">PRIVACY POLICY</h2>
    <h3 class="section-subtitle">At Nirdhara Fruit Products Private Limited (“we,” “us,” or “our”), we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website.</h3>
    <ol class="ordered-list">
      <li>
        <span class="list-item-title">Information We Collect
          <br>We collect two types of information:
        </span>
        <ul class="nested-list">
          <li>
            <strong>Personal Information:</strong> When you place an order or contact us, we collect details such as your name, email address, phone number, billing/shipping addresses, and payment information.
          </li>
          <li>
            <strong>Non-Personal Information:</strong> We automatically collect data like your IP address, browser type, device information, and how you interact with our Site (using cookies).
          </li>
        </ul>
      </li>
      <li>
        <span class="list-item-title">How We Use Your Information
          <br>We use your information to:
        </span>
        <ul class="nested-list">
          <li>
            Process and deliver your orders.
          </li>
          <li>
            Communicate with you about your orders or inquiries.
          </li>
          <li>
            Improve our website and services.
          </li>
          <li>
            Comply with legal requirements.
          </li>
        </ul>
      </li>
      <li>
        <span class="list-item-title">Sharing Your Information</span>
        <ul class="nested-list">
          <li>
            We do not sell your personal information. We may share it with trusted third parties (like payment processors and delivery partners) to fulfill your orders or as required by law.
          </li>
        </ul>
      </li>
      <li>
        <span class="list-item-title">Data Security</span>
        <ul class="nested-list">
          <li>
            We use encryption and other security measures to protect your personal data. While we take steps to protect your information, no method of data transmission is fully secure.
          </li>
        </ul>
      </li>
      <li>
        <span class="list-item-title">Your Rights</span>
        <ul class="nested-list">
          <li>
            You have the right to access, correct, or delete your personal information. You can also unsubscribe from our promotional emails at any time by clicking the unsubscribe link.
          </li>
        </ul>
      </li>
      <li>
        <span class="list-item-title">Cookies</span>
        <ul class="nested-list">
          <li>
            We use cookies to enhance your experience on our Site. You can manage or disable cookies in your browser settings.
          </li>
        </ul>
      </li>
      <li>
        <span class="list-item-title">Children’s Privacy</span>
        <ul class="nested-list">
          <li>
            Our Site is not intended for children under 13. We do not knowingly collect information from children.
          </li>
        </ul>
      </li>
      <li>
        <span class="list-item-title">Changes to This Policy</span>
        <ul class="nested-list">
          <li>
            We may update this Privacy Policy. Any changes will be posted here with an updated "Effective Date."
          </li>
        </ul>
      </li>
    </ol>

    <!-- CHANGES TO TERMS -->
    <h2 class="section-title">CHANGES TO TERMS</h2>
    <ol class="ordered-list">
      <li class="max-w-[92ch] break-words">
        Nirdhara reserves the right to modify these terms at any time. Any changes will be communicated to you via email or through our website. 
      </li>
    </ol>

  </section>
</div>
</main>


<main class="flex flex-col">

  <!-- Page Title -->
  <h1 class="mt-20 text-3xl font-normal text-center sm:text-4xl lg:text-5xl 2xl:text-custom-font-65 2xl:leading-custom-line-73 text-custom-gray">
    <span class="mr-3 font-times lg:mr-4">Shipping Policy for</span>
    <span class="mr-3 italic font-times lg:mr-4">Fresh Juices</span>
    <span class="block font-times sm:inline">Delivery</span>
  </h1>
<div class="px-10 ml-5 lg:px-20 2xl:px-36">
  <!-- Subtitle -->
  <h1 class="font-poppins text-sm xl:text-lg 3xl:text-xl font-bold text-custom-gray flex mt-8 tracking-[2px] 3xl:tracking-[4px]">
    At Nirdhara, we are committed to delivering the freshest and most delicious juices directly to your doorstep every morning. Please review our shipping policy to understand how our delivery process works.
  </h1>

  <section class="max-w-6xl w-full text-left text-[#2F2C2B] mt-28 space-y-6">
      
      <h2 class="section-title">Delivery Hours:</h2>
      <ol class="ordered-list">
          <li class="max-w-[95ch] break-words">
            <strong>Delivery Timeframe: We deliver fresh juices every morning between **6:00 AM and 11:00 AM.</strong>
          </li>
          <li class="max-w-[90ch] break-words">
            <strong>Based on Orders:</strong>All deliveries are made based on the orders placed the previous day or as specified by the customer.
          </li>                   
      </ol>

      <h2 class="section-title">Ordering:</h2>
      <ol class="ordered-list">
        <li class="max-w-[95ch] break-words">
          <strong>Order Deadlines:</strong>To ensure timely delivery, orders must be placed by 11:00 PM the day before delivery.
        </li>        
        <li>
          <strong>Order Confirmation:</strong>Once your order is received, you will receive a confirmation email with your delivery window (within 6:00 AM – 11:00 AM).
        </li>
    </ol>

    <h2 class="section-title">Delivery Process:</h2>
    <ol class="ordered-list">
      <li class="max-w-[95ch] break-words">
        <strong>Flexible Delivery Window:</strong>Due to the nature of fresh juice preparation, deliveries will occur within the specified time window (6:00 AM – 11:00 AM), and you can expect your order to arrive at any time during this period.
      </li>        
      <li>
        <strong>Local Delivery Areas:</strong>We deliver within [specific delivery zones or city limits] only. Please verify that your delivery address falls within our serviceable area.
      </li>
    </ol>

    <h2 class="section-title">Delivery Issues:</h2>
    <ol class="ordered-list">
      <li class="max-w-[92ch] break-words">
        <strong>Missed Deliveries:</strong>If you miss your delivery, please contact us as soon as possible. We cannot guarantee re-delivery for missed orders, but we'll do our best to accommodate you for future deliveries.     
     </li>        
      <li>
        <strong>Order Changes or Cancellations: If you need to modify or cancel your order, please notify us **[time frame, e.g., 12 hours before the scheduled delivery].</strong>
      </li>
    </ol>

  </section>
</div>
</main> 


<h1 class="p-2 mt-40 text-3xl font-normal text-center sm:text-4xl 2xl:text-6xl 2xl:leading-custom-line-73 text-custom-gray md:p-0">
  <span class="mr-3 font-times lg:mr-4">Thank you for choosing</span>
  <span class="mr-3 italic font-times lg:mr-4">Nirdhara.</span><br/>
  <span class="block mr-3 font-times sm:inline lg:mr-4">We look forward to serving you with</span>
  <span class="mr-3 italic font-times lg:mr-4 text-nowrap">the best fresh juice</span><br/>
  <span class="block font-times sm:inline">delivery service available.</span>
</h1>
<div class="h-40 lg:h-60">

</div>
</body>
<app-footer></app-footer>