import { Component, OnInit } from '@angular/core';
import { ApiService, Product, ProductImage } from '../api.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  activeTab: string = 'Product';
  products: Product[] = []; // Array to store the products
  filteredItems: Product[] = [];
  searchQuery: string = ''; // Variable to store the search term

  showPopup: boolean = false;
  editMode: boolean = false;
  selectedProduct: Product = {
    product_master_id: '',
    product_name: '',
    quantity: '',
    price: 0,
    product_description: '', // Added this
    juice_name: '', // Added this
    product_images: [], // Added this
  };
  isPopupOpen: boolean = false; // Declare it here
  // Track which descriptions are expanded
  expanded: boolean[] = [];

  currentPage: number = 1; // Current page
  itemsPerPage: number = 6; // Number of rows per page
  totalPages: number = 0; // Total pages
  pageNumbers: number[] = []; // Array for pagination numbers

  constructor(private apiService: ApiService, private snackBar: MatSnackBar) {
        // Initialize the expanded array with false for all products
        this.expanded = new Array(this.filteredItems.length).fill(false);
  }

  ngOnInit() {
    this.loadProducts(); // Fetch products when the component initializes
  }

  loadProducts() {
    this.apiService.getProducts().subscribe(
      (data) => {
        this.products = data.map((product) => ({
          ...product,
          product_images: product.product_images.map((img: ProductImage) => ({
            ...img,
            image_url: `${this.apiService.baseUrl}/${img.image_url}`,
          })),
        }));
        this.filteredItems = this.products;
        this.updatePagination();
      },
      (error) => console.log('Error loading products')
    );
  }

    // Method to update pagination
    updatePagination() {
      this.totalPages = Math.ceil(this.filteredItems.length / this.itemsPerPage);
      this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);
      this.goToPage(this.currentPage);
    }
  
    // Navigate to a specific page
    goToPage(page: number) {
      this.currentPage = page;
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.filteredItems = this.products.slice(startIndex, endIndex);
  
      // Update the expanded array for the current page
      this.expanded = new Array(this.filteredItems.length).fill(false);
    }
  
  // Method to filter items based on search query
  filterItems() {
    if (this.searchQuery.trim()) {
      const lowerCaseQuery = this.searchQuery.trim().toLowerCase();
      this.filteredItems = this.products.filter((product) => {
        const nameMatch = product.product_name
          .toLowerCase()
          .includes(lowerCaseQuery);
  
        const juiceNameMatch = product.juice_name
          .toLowerCase()
          .includes(lowerCaseQuery);
  
        // Convert quantity to string and check for exact match
        const quantityString = product.quantity
          ? product.quantity.toString().toLowerCase()
          : '';
        const quantityMatch = quantityString === lowerCaseQuery; // Exact match for quantity
  
        // Convert price to string and check for exact match
        const priceString = product.price !== null ? product.price.toString() : '';
        const priceMatch = priceString === lowerCaseQuery; // Exact match for price
  
        this.updatePagination();
        return nameMatch || juiceNameMatch || quantityMatch || priceMatch;
      });
    } else {
      this.filteredItems = [...this.products]; // Reset to all items if no search query
      this.updatePagination();
    }
  }  

  togglePopup() {
    this.editMode = false;
    this.resetForm();
    this.showPopup = !this.showPopup; // Toggle popup open/close state
  }

  resetForm() {
    // Correct the property name to selectedProduct
    this.selectedProduct = {
      product_master_id: '',
      product_name: '',
      quantity: '',
      price: 0,
      product_description: '', // Reset this
      juice_name: '', // Reset this
      product_images: [], // Reset this
    };
  }

  closePopup() {
    this.showPopup = false;
  }

  editProduct(product: Product) {
    this.editMode = true;
    this.selectedProduct = product;
    this.showPopup = true; // Use this to open the modal
  }

  handleProductUpdated(product: Product) {
    this.loadProducts();
    this.togglePopup();
  }

  deleteProduct(productMasterId: string) {
    this.apiService.deleteProduct(productMasterId).subscribe(
      () => {
        this.openSnackBar('Product deleted successfully', 'custom-snackbar');
        this.loadProducts();
      },
      (error) =>{
        const errorMessage = error?.error?.detail || 'Error deleting product';
        // console.error('Error during registration:', errorMessage);
        console.log(errorMessage);
    });
  }

  toggleDescription(index: number): void {
    this.expanded[index] = !this.expanded[index];
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    config.verticalPosition = 'top'; // Position at the top
    config.horizontalPosition = 'center'; // Position at the center horizontally
  
    this.snackBar.open(message, 'Close', config);
  }
  
}