<!-- product-popup.component.html -->
<div
  class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 font-poppins"
>
  <div class="bg-white rounded-lg shadow-lg w-[95%] max-w-2xl p-3 sm:p-6">
    <button
      class="float-right text-2xl font-bold text-custom-gray hover:text-black focus:outline-none"
      (click)="closePopup()"
    >
      ×
    </button>
    <h2
      class="pb-2 mb-3 text-2xl font-medium border-b-2 text-custom-gray border-custom-teal sm:pb-4"
    >
      {{ editMode ? "Edit Product" : "Add Product" }}
    </h2>

    <form
      [formGroup]="productForm"
      (ngSubmit)="onSubmit()"
      class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6"
    >
      <!-- Product Name -->
      <div class="relative mb-2 sm:mb-6">
        <label for="product_name" class="block mb-1 ml-6 text-gray-700"
          >Product Name</label
        >
        <div class="flex items-center">
          <i class="mr-2 text-xl fas fa-wine-bottle text-custom-gray"></i>
          <input
            id="product_name"
            formControlName="product_name"
            class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 uppercase transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
          />
        </div>
        <div
          *ngIf="showError('product_name')"
          class="mt-2 ml-6 text-xs text-red-600"
        >
          {{ getErrorMessage("product_name") }}
        </div>
      </div>

      <!-- Quantity -->
      <div class="relative mb-2 sm:mb-6">
        <label for="quantity" class="block mb-1 ml-6 text-gray-700"
          >Quantity</label
        >
        <div class="flex items-center">
          <i
            class="mr-2 text-xl fas fa-sort-amount-up-alt text-custom-gray"
          ></i>
          <input
            id="quantity"
            formControlName="quantity"
            required
            class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
          />
        </div>
        <div
          *ngIf="showError('quantity')"
          class="mt-2 ml-6 text-xs text-red-600"
        >
          {{ getErrorMessage("quantity") }}
        </div>
      </div>

      <!-- Price -->
      <div class="relative mb-2 sm:mb-6">
        <label for="price" class="block mb-1 ml-6 text-gray-700">Price</label>
        <div class="flex items-center">
          <i class="mr-3 text-xl fas fa-rupee-sign text-custom-gray"></i>
          <input
            id="price"
            formControlName="price"
            type="text"
            required
            class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
          />
        </div>
        <div *ngIf="showError('price')" class="mt-2 ml-6 text-xs text-red-600">
          {{ getErrorMessage("price") }}
        </div>
      </div>

      <!-- Juice Name -->
      <div class="relative mb-2 sm:mb-6">
        <label for="juice_name" class="block mb-1 ml-6 text-gray-700"
          >Juice Name</label
        >
        <div class="flex items-center">
          <i class="mr-3 text-xl fas fa-martini-glass text-custom-gray"></i>
          <input
            id="juice_name"
            formControlName="juice_name"
            class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 uppercase transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
          />
        </div>
        <div
          *ngIf="showError('juice_name')"
          class="mt-2 ml-6 text-xs text-red-600"
        >
          {{ getErrorMessage("juice_name") }}
        </div>
      </div>

      <!-- Product Description -->
      <div class="relative mb-2 sm:mb-6">
        <label for="product_description" class="block mb-1 ml-6 text-gray-700"
          >Product Description</label
        >
        <div class="flex items-center">
          <i class="mr-3 text-xl fas fa-circle-info text-custom-gray"></i>
          <textarea
            id="product_description"
            formControlName="product_description"
            rows="2"
            class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
          ></textarea>
        </div>
        <div
          *ngIf="showError('product_description')"
          class="mt-2 ml-6 text-xs text-red-600"
        >
          {{ getErrorMessage("product_description") }}
        </div>
      </div>

<!-- Product Images -->
<div class="relative mb-2 sm:mb-6">
  <label for="product_images" class="block mb-1 ml-6 text-gray-700">Product Images</label>
  <div class="flex items-center">
    <i class="mr-3 text-xl fas fa-image text-custom-gray"></i>
    <input
      id="product_images"
      type="file"
      accept="image/*"
      #fileInput
      class="flex-1 w-full min-w-0 px-2 py-1 text-sm text-gray-600 transition duration-300 ease-in-out bg-white border rounded-md shadow-sm outline-none sm:py-2 sm:px-4 focus:border-custom-teal focus:shadow-lg"
      (change)="onFileChange($event)"
    />
  </div>
  <div *ngIf="showError('product_images')" class="mt-2 ml-6 text-xs text-red-600">
    {{ getErrorMessage('product_images') }}
  </div>

  <!-- Preview Section -->
  <div class="flex flex-wrap gap-4 mt-4 ml-8">
    <div *ngIf="imagePreviews.length > 0" class="relative w-20 h-20">
      <img
        [src]="imagePreviews[0]"
        alt="Product Preview"
        class="object-cover w-full h-full border rounded-md shadow-sm"
      />
      <!-- Close Icon -->
      <button
        type="button"
        class="absolute w-6 h-6 text-white rounded-full -right-2 -top-2 bg-custom-gray"
        (click)="removeImage(0)"
      >
        ✕
      </button>
    </div>
  </div>
</div>


      <!-- Centered Error Detail and Submit Button -->
      <div class="mb-2 text-center col-span-full">
        <div
          *ngIf="detail"
          class="text-sm"
          [ngClass]="{
            'text-green-600': messageType === 'success',
            'text-red-600': messageType === 'error'
          }"
        >
          {{ detail }}
        </div>
        <button
          type="submit"
          class="px-2 py-1 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-times lg:text-lg w-fit"
        >
          {{ editMode ? "Update" : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</div>
