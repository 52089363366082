import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { SubscriptionData } from './services/subscription-data.service';

interface ApiResponse {
  message: string;
  session_user_id: string;
  session_role_id: string;
}

// Define the product response type
export interface Product {
  product_master_id: string;
  product_name: string;
  quantity: string;
  price: number | null; // Allow null values
  product_description: string; // Include description
  juice_name: string; // Add juice_name
  product_images: ProductImage[]; // Add product images
}

export interface ProductImage {
  product_master_id: string;
  image_url: string;
}

export interface Address {
  address_id: string;
  first_name: string;
  last_name: string;
  mobile: string;
  address: string;
  area: string; 
  city: string;
  state: string;
  zip_code: string;
}

export interface UserWithAddress {
  user_id: string;
  email: string;
  mobile: string;
  user_role_id: string;
  is_active: boolean;
  is_deleted: boolean;
  created_at: Date;
  updated_at: Date;
  address: Address;
}

export interface CartResponse {
  cart_id: string;
  product_master_id: string;
  subscription_type: string;
  created_by: string;
  updated_by?: string;
  is_deleted: boolean;
  product_name: string;
  quantity: number;
  price: number;
  selected: boolean; // New property to track selection
  start_date?: Date;

}

// Request interface for fetching product price
export interface ProductPriceRequest {
  product_name: string;
  quantity: number;
}

// Response interface for product price API
export interface ProductPriceResponse {
  price: number;
  product_master_id: string; // Assuming the response might include a currency field
}

// Subscription data interface matching the response structure of get_subscriptions api
export interface UserSubscription {
  subscription_id: string;
  product_master_id: string;
  product: {
    product_name: string;
    quantity: number;
    price: number;
  };
  address: {
    first_name: string;
    last_name: string;
    address: string;
    area: string;
    city: string;
    state: string;
    zip_code: string;
    mobile: string;
  };
  email: string;
  subscription_type: string;
  start_date: Date;
  is_active: boolean;
  is_paid: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public baseUrl = 'https://nirdhara.com/route'; // Base URL for the backend API prod

  // public baseUrl = 'http://localhost:8000'; // Base URL for the backend API local

  constructor(private http: HttpClient) {}

 // Method to get headers for requests
 private getHeaders(): HttpHeaders {
  const userId = localStorage.getItem('session_user_id') || '';
  const roleId = localStorage.getItem('session_role_id') || '';

  return new HttpHeaders({
    'Session-User-ID': userId,
    'Session-Role-ID': roleId,
  });
}

  // Create a new user
  createUser(userData: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/create_user/`, userData);
  }

  // Verify email
  verifyEmail(email: string, code: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/verify_email/`, {
      email,
      code,
    });
  }

  // Login method
  login(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.baseUrl}/users/login/`, data).pipe(
      tap(response => {
        if (response.session_user_id && response.session_role_id) {
          localStorage.setItem('session_user_id', response.session_user_id);
          localStorage.setItem('session_role_id', response.session_role_id);
          localStorage.setItem('isLoggedIn', 'true'); // Add this if not already present
        }
      })
    );
  }

// Logout method
logout(): Observable<ApiResponse> {
  const headers = this.getHeaders();
  return this.http.post<ApiResponse>(`${this.baseUrl}/users/logout/`, {}, { headers }).pipe(
    tap(response => {
      if (response.message === 'Logout successful') {
        localStorage.removeItem('session_user_id');
        localStorage.removeItem('session_role_id');
        localStorage.setItem('isLoggedIn', 'false'); // Update this if you use it to manage login state
      }
    })
  );
}


  // Send OTP to user's email
  sendOtp(email: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.baseUrl}/users/send_otp/`, { email });
  }

  // Verify OTP
  verifyOtp(email: string, code: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.baseUrl}/users/forgot_password/`, { email, code });
  }

  // Reset password
  resetPassword(email: string, code: string, new_password: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.baseUrl}/users/reset_password/`, { email, code, new_password });
  }

  // Get user by ID
  getUser(userId: number): Observable<any> {
    const url = `${this.baseUrl}/users/get_user/${userId}`;
    return this.http.get(url);
  }

  // Update user by ID
  updateUser(userId: number, userData: any): Observable<any> {
    const url = `${this.baseUrl}/users/update_user/${userId}`;
    return this.http.put(url, userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

// Method to fetch addresses
getAddresses(): Observable<Address[]> {
  return this.http.get<Address[]>(`${this.baseUrl}/users_address/get_addresses/`, { headers: this.getHeaders() });
}

// Method to create a new address
createAddress(addressData: Address): Observable<Address> {
  const headers = this.getHeaders();
  return this.http.post<Address>(`${this.baseUrl}/users_address/create_address/`, addressData, { headers });
}

deleteAddress(addressId: string): Observable<void> {
  const headers = this.getHeaders();
  return this.http.delete<void>(`${this.baseUrl}/users_address/delete_address/`, {headers,
    body: { address_id: addressId }
  });
}

// Method to update an existing address
updateAddress(addressData: Address): Observable<Address> {
  const headers = this.getHeaders();
  return this.http.put<Address>(`${this.baseUrl}/users_address/update_address/`, addressData, { headers });
}

// Method to create a new cart
createCart(cartData: SubscriptionData): Observable<any> {
  const headers = this.getHeaders();
  return this.http.post<any>(`${this.baseUrl}/cart/create_cart/`, cartData, { headers });
}

getUserCart(): Observable<CartResponse[]> {
  const headers = this.getHeaders();
  return this.http.get<CartResponse[]>(`${this.baseUrl}/cart/get_user_cart/`, { headers });
}

deleteCart(cartId: string): Observable<any> {
  const headers = this.getHeaders();
  return this.http.delete<any>(`${this.baseUrl}/cart/delete_cart/`, {
    headers,
    body: { cart_id: cartId }
  });
}

 // Method to fetch product price
 getProductPrice(productRequest: ProductPriceRequest): Observable<ProductPriceResponse> {
  const headers = this.getHeaders();
  return this.http.post<ProductPriceResponse>(
    `${this.baseUrl}/products/get_product_price/`,
    productRequest,
    { headers }
  );
}

// Method to check subscription
checkSubscription(subscriptionData: SubscriptionData): Observable<any> {
  const headers = this.getHeaders();
  return this.http.post<any>(`${this.baseUrl}/subscription/check_subscription/`, subscriptionData, { headers });
}

createSubscription(subscriptionData: any): Observable<any> {
  const headers = this.getHeaders();
  return this.http.post<any>(`${this.baseUrl}/subscription/create_subscription/`, subscriptionData, { headers });
}

// Method to get the list of products
getProducts(): Observable<Product[]> {
  const headers = this.getHeaders();
  return this.http.get<Product[]>(`${this.baseUrl}/products/get_products/`, { headers });
}

createProduct(product: FormData): Observable<Product> {
  const headers = this.getHeaders();
  return this.http.post<Product>(`${this.baseUrl}/products/create_product/`, product, { headers });
}

updateProduct(product: FormData): Observable<void> {
  const headers = this.getHeaders();
  return this.http.put<void>(`${this.baseUrl}/products/update_product/`, product, { headers });
}

deleteProduct(productMasterId: string): Observable<void> {
  const headers = this.getHeaders();
  return this.http.delete<void>(`${this.baseUrl}/products/delete_product/`, {
    headers,
    body: { product_master_id: productMasterId }
  });
}

// Method to get the list of users with their addresses
getUserList(): Observable<UserWithAddress[]> {
  const headers = this.getHeaders();
  return this.http.get<UserWithAddress[]>(`${this.baseUrl}/users/get_user_list/`, { headers });
}

// Delete a user by their ID
deleteUser(userId: string): Observable<any> {
  const headers = this.getHeaders();
  return this.http.delete(`${this.baseUrl}/users/delete_user/`, {
    headers,
    body: { user_id: userId },
  });
}

// Add the API call to fetch subscriptions
getSubscriptions(): Observable<UserSubscription[]> {
  const headers = this.getHeaders();
  return this.http.get<UserSubscription[]>(`${this.baseUrl}/subscription/get_subscriptions/`, { headers });
}

}
