import { Component, ViewChild } from '@angular/core';
import { ApiService, CartResponse } from '../api.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.css'
})
export class CartComponent {
  @ViewChild(HeaderComponent) headerComponent!: HeaderComponent;

  cartItems: CartResponse[] = [];
  selectedItems: CartResponse[] = [];

  subtotal: number = 0;
  selectAll: boolean = false;
  isEmpty: boolean = true;  // Added to track if the cart is empty
  showPopup: boolean = false;  // Controls the visibility of the popup
  minDateTime: string = ''; // Minimum date for the input
  cartCount: number = 0;
  invalidTimeErrors: boolean[] = []; // Array to track errors for each item
  missingStartDateError: boolean = false; // Flag for missing start date error
  isLoggedIn: boolean = false;
  loading: boolean = true;  // New loading state

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private router: Router,
  ){}

  ngOnInit(): void {
    // Check if the user is logged in
    this.isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    // Check login status and load cart only once
    if (this.isLoggedIn) {
      setTimeout(() => {
        this.loadCart();
      }, 500);
    }
    this.setMinDate(); // Set the minimum date on initialization
  }

// This method will handle input changes for the start_date field
onStartDateChange(event: any, index: number) {
  const selectedDate = new Date(event.target.value);
  console.log("Selected Date:", selectedDate); // Debugging output
  this.validateStartDate(selectedDate, index); // Call validation on each change
}


// Method to validate the start_date
validateStartDate(selectedDate: Date | undefined, index: number) {
  if (!selectedDate) {
    this.invalidTimeErrors[index] = false; // No error if the field is empty or undefined
    return;
  }

  this.selectedItems.forEach((item, index) => {
    if (item.start_date && new Date(item.start_date) < new Date()) {
      this.invalidTimeErrors[index] = true;
      this.clearErrorsAfterDelay();
      return;
    } else {
      this.invalidTimeErrors[index] = false;
    }
  });
}

  toggleSelectAll(): void {
    this.cartItems.forEach(item => item.selected = this.selectAll);
    this.calculateSubtotal();
  }

  setMinDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    this.minDateTime = `${year}-${month}-${day}T00:00`; // Set the minimum date for the input
  }

  loadCart(): void {
    this.loading = true;  // Set loading to true before API call
    this.apiService.getUserCart().subscribe(
      (data) => {
        // Initialize the selected property for each item
        this.cartItems = data.map(item => ({ ...item, selected: false }));
        this.isEmpty = this.cartItems.length === 0;  // Check if the cart is empty

        this.cartCount = this.cartItems.length;
  
        this.calculateSubtotal();
        
        this.loading = false;  // Set loading to false after data is loaded
      },
      (error) => {
        // Handle error appropriately
        console.error('Failed to load cart items', error);
        this.loading = false;  // Ensure loading is false even on error 
      }
    );
  }
  
  cartCounting(): number {
    console.log(`Number of items in the cart: ${this.cartCount}`);
    return this.cartCount;
  }

  calculateSubtotal(): void {
    this.subtotal = this.cartItems
      .filter(item => item.selected)  // Filter selected items
      .reduce((sum, item) => sum + (item.price), 0);  // Calculate subtotal based on selected items
  }

  get selectedItemsCount(): number {
    return this.cartItems.filter(item => item.selected).length;
  }

  deleteItem(cartId: string): void {
    this.apiService.deleteCart(cartId).subscribe(
      () => {
        this.openSnackBar('Cart item deleted successfully!', 'custom-snackbar');
        this.loadCart(); // Reload the cart items
        this.headerComponent.loadCart();
      },
      (error) => {
        console.error('Failed to delete cart item', error);
      }
    );
  }

  redirectToShop(): void {
    // Replace with your actual shop route
    window.location.href = '/subscribe';
  }
  
  proceedToCheckout(): void {
    this.selectedItems = this.cartItems.filter(item => item.selected);
    if (this.selectedItems.length > 0) {
      this.openPopup(); // Open popup for date selection
    } else {
      this.openSnackBar('Please select at least one item to proceed.', 'custom-snackbar');
    }
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    config.verticalPosition = 'top'; // Position at the top
    config.horizontalPosition = 'center'; // Position at the center horizontally
  
    this.snackBar.open(message, 'Close', config);
  }
  
  openPopup(): void {
    this.showPopup = true;
    this.selectedItems = this.cartItems.filter(item => item.selected);
  }

  resetStartDateFields(): void {
    this.selectedItems.forEach(item => {
      item.start_date = undefined; // Reset the start_date field to an empty value
    });
  }
  
  closePopup(): void {
    this.resetStartDateFields();  // Reset the start_date fields
    this.showPopup = false;       // Close the popup
  }  

  confirmCheckout(): void {
    // Check if any item has an invalid start date (before current date)
    this.selectedItems.forEach((item, index) => {
      if (item.start_date && new Date(item.start_date) < new Date()) {
        this.invalidTimeErrors[index] = true; // Use index to set error
        this.clearErrorsAfterDelay();
        return; // Prevent checkout if any date is in the past
      } else {
        this.invalidTimeErrors[index] = false; // Clear the error if valid
      }
    });
  
    // If any invalid date was found, return without proceeding to checkout
    if (this.invalidTimeErrors.some(error => error)) {
      return;
    }
  
    // Check if any item is missing a start date
    if (this.selectedItems.some(item => !item.start_date)) {
      this.missingStartDateError = true; // Set the error flag
      this.clearErrorsAfterDelay();
      return; // Prevent checkout if start date is missing
    }
  
    // Clear error flag on successful validation
    this.missingStartDateError = false;
  
    // Store selected items and their start dates in local storage
    localStorage.setItem('selectedCartItems', JSON.stringify(this.selectedItems));
  
    // Close the popup
    this.showPopup = false;
  
    // Redirect to checkout page
    this.router.navigate(['/checkout']);
  }  

  clearErrorsAfterDelay() {
    setTimeout(() => {
      this.missingStartDateError = false;
      this.invalidTimeErrors = []; // Clear invalid time error

    }, 3000);
  }
}