import { Component, OnInit, OnDestroy  } from '@angular/core';
import { SubscriptionDataService, SubscriptionData } from '../services/subscription-data.service';
import { FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiService, Address } from '../api.service';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavigationEnd } from '@angular/router';

// Utility function to convert a string to title case and trim whitespace
function toTitleCaseAndTrim(input: string): string {
  return input
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .trim();
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'] // Fixed styleUrls to use an array
})

export class CheckoutComponent implements OnInit,OnDestroy {

  subscriptionData: SubscriptionData | null = null;
  addresses: Address[] = [];
  userId: string | null = null; // Declare userId as a class property
  addressForm: FormGroup;
  isModalOpen = false; // Manage modal open/close state
  isChangeAddressMode = false; // Track address change mode
  currentAddress: Address | null = null; // Track the currently selected address
  selectedAddress: Address | null = null; // Track the selected address for change

  selectedPayment: string = 'razorpay';
  isRazorpaySelected: boolean = false;
  isEditModalOpen = false; // Initialize isEditModalOpen

  selectedCartItems: any[] = [];
  totalCartPrice: number = 0;
  errorMessage: string = '';
  successMessage: string = ''; // Add successMessage property
  private routerSubscription!: Subscription;

  constructor(
    private subscriptionDataService: SubscriptionDataService,
    private apiService: ApiService,
    private fb: FormBuilder, 
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.addressForm = this.fb.group({
      first_name: ['', [Validators.required, Validators.pattern(/^[A-Za-z]+$/)]], // Only letters
      last_name: ['', [Validators.required, Validators.pattern(/^[A-Za-z]+$/)]], // Only letters
      mobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]], // 10-digit number
      address: ['', [Validators.required, Validators.maxLength(200)]], // Limit to 200 characters
      area: ['', [Validators.required, Validators.maxLength(50)]], // Limit to 50 characters
      city: ['', [Validators.required, this.cityValidator]],
      state: ['', [Validators.required, Validators.pattern(/^[A-Za-z\s]+$/), Validators.maxLength(20)]], // Only letters and spaces and a max length of 20
      zip_code: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]] //6 digit number
    });
  }

  // Custom validator for the city field
  cityValidator(control: AbstractControl) {
    const validCities = ['Pune', 'pune'];
    const value = control.value?.trim();
    if (!value) {
      return null; // Let the 'required' validator handle empty values
    }
    return validCities.includes(value) ? null : { invalidCity: true };
  }  

  openAddressModal() {
    this.isModalOpen = true;
  }

  closeAddressModal() {
    this.isModalOpen = false;
    this.addressForm.reset();
    this.autoClearFormErrors(); // Clears the form errors after 3 seconds
    this.autoClearApiMessage();
  }

  toggleAddressChange() {
    this.isChangeAddressMode = !this.isChangeAddressMode;
    if (this.isChangeAddressMode && this.currentAddress) {
      this.selectedAddress = this.currentAddress; // Set the selected address to the current address
    }
  }  

  selectAddress(address: Address) {
    this.selectedAddress = address; // Store the selected address
  }

  useSelectedAddress() {
    if (this.selectedAddress) {
      this.currentAddress = this.selectedAddress;
    }
    this.isChangeAddressMode = false;
  }

  togglePaymentMethod(): void {
    this.isRazorpaySelected = !this.isRazorpaySelected;
  }

  ngOnInit() {
    this.loadAddresses();
    this.userId = localStorage.getItem('session_user_id');
  
   this.subscriptionDataService.getSubscriptionData().subscribe(data => {
      if (data) {
        this.subscriptionData = {
          ...data,
          start_date: new Date(data.start_date) // Ensure start_date is a Date object
        };
      } else {
        // console.log("No subscription data available.");
      }
    });

  // Fetch selected cart items from local storage
  const cartItems = localStorage.getItem('selectedCartItems');
  if (cartItems) {
    this.selectedCartItems = JSON.parse(cartItems);
    this.calculateTotalCartPrice();
  }

  this.routerSubscription = this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      this.subscriptionDataService.clearSubscriptionData();
    }
  });

  }

  ngOnDestroy() {
    // Clear subscription data when the component is destroyed
    this.subscriptionDataService.clearSubscriptionData();
    // Unsubscribe from router events
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
  
  calculateTotalCartPrice(): void {
    this.totalCartPrice = this.selectedCartItems.reduce(
      (total, item) => total + item.price,
      0
    );
  }


  loadAddresses(): void {
    this.apiService.getAddresses().subscribe({
      next: (data) => {
        this.addresses = data;
        if (this.addresses.length > 0) {
          this.currentAddress = this.addresses[this.addresses.length - 1]; // Default to the latest address
        }
      },
      error: (error) => {
        const errorMessage = error.error.detail || 'Error fetching addresses!';
        // console.error('Error fetching addresses', error);
        this.openSnackBar(errorMessage, 'custom-snackbar');
      }
    });
  }

  // Method to delete an address
  deleteAddress(addressId: string) {
    this.apiService.deleteAddress(addressId).subscribe({
      next: () => {
        this.openSnackBar('Address deleted successfully!', 'custom-snackbar');
        this.loadAddresses(); // Refresh the list of addresses
      },
      error: (error) => {
        const errorMessage = error.error.detail || 'Error deleting address!';
        // console.error('Error deleting address:', errorMessage);
        this.openSnackBar(errorMessage, 'custom-snackbar');
      }
    });
  }
 

 // Method to submit the address form
onSubmit() {
  if (this.addressForm.invalid) {
    this.addressForm.markAllAsTouched(); // Highlight invalid fields
    this.autoClearFormErrors(); // Clears the form errors after 3 seconds
    this.autoClearApiMessage();
    return; // Exit early if the form is invalid
  }

  // Proceed with form submission if valid
  const formData = this.addressForm.value;

  // Convert form data to title case and trim
  const transformedData: Address = {
    address_id: this.currentAddress?.address_id || '', // Include address_id if editing
    first_name: toTitleCaseAndTrim(formData.first_name),
    last_name: toTitleCaseAndTrim(formData.last_name),
    mobile: formData.mobile.trim(),
    address: toTitleCaseAndTrim(formData.address),
    area: toTitleCaseAndTrim(formData.area),
    city: toTitleCaseAndTrim(formData.city),
    state: toTitleCaseAndTrim(formData.state),
    zip_code: formData.zip_code.trim()
  };

  // console.log('Transformed Data:', transformedData);

  // Call the API service to submit the data
  this.apiService.createAddress(transformedData).subscribe(
    (response) => {
      // Show success message in the form
      this.successMessage = 'Address added successfully!';
      this.autoClearApiMessage();
      setTimeout(() => {
          // Reload the addresses list
          this.loadAddresses();
          // Close the address modal
          this.closeAddressModal();
      }, 1000); // 3 seconds

    },
    (error) => {
      // console.error('Error adding address:', errorMessage);
      // Set the error message instead of using snackbar
      this.errorMessage = error.error.detail || 'Error adding address';
      this.autoClearApiMessage();
    }
  );
}

  autoClearFormErrors() {
    setTimeout(() => {
      Object.keys(this.addressForm.controls).forEach((key) => {
        const control = this.addressForm.get(key);
        control?.markAsUntouched(); // Clear the "touched" state
      });
    }, 3000); // 3 seconds
  }

  autoClearApiMessage() {
    setTimeout(() => {
      this.errorMessage = '';
      this.successMessage = '';
    }, 3000); // Clear after 3 seconds
  }

  openEditModal(address: Address) {
    this.selectedAddress = { ...address }; // Create a copy of the address to edit
    this.addressForm.setValue({
      first_name: this.selectedAddress.first_name,
      last_name: this.selectedAddress.last_name,
      mobile: this.selectedAddress.mobile,
      address: this.selectedAddress.address,
      area: this.selectedAddress.area,
      city: this.selectedAddress.city,
      state: this.selectedAddress.state,
      zip_code: this.selectedAddress.zip_code,
    });
    this.isEditModalOpen = true; // Track whether the edit modal is open
  }
  
  closeEditModal() {
    this.isEditModalOpen = false;
    this.addressForm.reset();
    this.autoClearFormErrors(); // Clears the form errors after 3 seconds
    this.autoClearApiMessage();
  }

  editSubmit() {
    if (this.addressForm.invalid) {
      this.addressForm.markAllAsTouched(); // Highlight invalid fields
      this.autoClearFormErrors(); // Clears the form errors after 3 seconds
      this.autoClearApiMessage();
      return; // Exit early if the form is invalid
    }
  
    // Proceed with form submission if valid
    const formData = this.addressForm.value;
  
    // Convert form data to title case and trim
    const transformedData: Address = {
      address_id: this.selectedAddress?.address_id || '', // Ensure we're editing the correct address
      first_name: toTitleCaseAndTrim(formData.first_name),
      last_name: toTitleCaseAndTrim(formData.last_name),
      mobile: formData.mobile.trim(),
      address: toTitleCaseAndTrim(formData.address),
      area: toTitleCaseAndTrim(formData.area),
      city: toTitleCaseAndTrim(formData.city),
      state: toTitleCaseAndTrim(formData.state),
      zip_code: formData.zip_code.trim()
    };
  
    // console.log('Editing Address:', transformedData);
  
    // Call the API service to update the address
    this.apiService.updateAddress(transformedData).subscribe(
      (response) => {
      // Show success message in the form
      this.successMessage = 'Address updated successfully!';
        setTimeout(() => {
          // Reload the addresses list
          this.loadAddresses();
          // Close the edit modal
          this.closeEditModal();
      }, 1000); // 3 seconds
      },
      (error) => {
        // console.error('Error updating address:', errorMessage);
        this.errorMessage = error.error.detail || 'Error updating address!';
        this.autoClearApiMessage();
      }
    );
  }
  
getTotalPrice(): number {
    return this.selectedCartItems.reduce((total, item) => total + item.price, 0);
  }

  getOrderTotal(): number {
    return this.subscriptionData ? this.subscriptionData.price : this.getTotalPrice();
  }

  placeOrder() {
    if (!this.currentAddress) {
      this.openSnackBar('Please select a valid address.','custom-snackbar');
      return;
    }
  
    const subscriptionData = this.getSubscriptionData();
    if (!subscriptionData) {
      console.log('No subscription data available.');
      return;
    }
  
    this.apiService.createSubscription(subscriptionData).subscribe(
      (response) => {
        // console.log('Subscription created successfully:', response);
        this.subscriptionDataService.markSubscriptionCreated(); // Mark subscription as created
        this.openSnackBar('Subscription created successfully!', 'custom-snackbar');
        // Handle successful response
        // Call deleteItem for each selected cart item
        this.selectedCartItems.forEach(item => {
          this.apiService.deleteCart(item.cart_id).subscribe(
              () => {
                  console.log(`Deleted cart item with ID: ${item.cart_id}`);
              },
              (error) => {
                  console.error('Failed to delete cart item', error);
              }
          );
      });
        // Redirect to /subscribe page
        this.router.navigate(['/subscribe']);
      },
      (error) => {
        const errorMessage = error.error.detail || 'Error creating subscription!';
        // console.error('Error creating subscription:', error);
        this.openSnackBar(errorMessage, 'custom-snackbar');
      }
    );
  }
  
  getSubscriptionData(): any {
    const subscriptionData = [];
  
    if (this.subscriptionData) {
      subscriptionData.push({
        product_master_id: this.subscriptionData.product_master_id,
        address_id: this.currentAddress?.address_id, // Add null check using optional chaining
        subscription_type: this.subscriptionData.subscription_type,
        start_date: this.subscriptionData.start_date,
        is_active: true,
        is_paid: false,
        created_by: localStorage.getItem('session_user_id') || ''
      });
    } else if (this.selectedCartItems.length > 0) {
      this.selectedCartItems.forEach((cartItem) => {
        subscriptionData.push({
          product_master_id: cartItem.product_master_id,
          address_id: this.currentAddress?.address_id, // Add null check using optional chaining
          subscription_type: cartItem.subscription_type,
          start_date: cartItem.start_date,
          cart_id:cartItem.cart_id,
          is_active: true,
          is_paid: false,
          created_by: localStorage.getItem('session_user_id') || ''
        });
      });
    }
  
    return subscriptionData;
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    config.verticalPosition = 'top'; // Position at the top
    config.horizontalPosition = 'center'; // Position at the center horizontally
  
    this.snackBar.open(message, 'Close', config);
  }
  
}
