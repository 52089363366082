import { Component } from '@angular/core';
import { ApiService, Product, ProductImage } from '../api.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-juice-options',
  templateUrl: './juice-options.component.html',
  styleUrl: './juice-options.component.css'
})
export class JuiceOptionsComponent {
  isLgScreen: boolean = false;
  products: Product[] = []; // Array to store the products
  showPopup: boolean = false;  // Flag to show or hide the popup
  selectedSubscriptionType: string = '';  // Selected subscription type
  selectedProductName: string = '';  // Selected product name

  constructor(private apiService: ApiService,private breakpointObserver: BreakpointObserver,private router: Router) {}

  ngOnInit() {
    this.loadProducts(); // Fetch products when the component initializes
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((result) => {
      this.isLgScreen = result.matches;
    });
  }  

  loadProducts() {
    this.apiService.getProducts().subscribe(
      (data) => {
        this.products = data.map((product) => ({
          ...product,
          product_images: product.product_images.map((img: ProductImage) => ({
            ...img,
            image_url: `${this.apiService.baseUrl}/${img.image_url}`,
          })),
        }));
      },
      (error) => console.log('Error loading products')
    );
  }

  handleSubscription(product: Product) {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'; // Check login status
  
    if (!isLoggedIn) {
      // Store the intended route
      localStorage.setItem('redirectUrl', this.router.url);
      // Redirect to login page
      this.router.navigate(['/login']);
    } else {
      // Proceed to the subscription process
      // console.log('Starting subscription for product:', product.product_name);
      // Add the subscription logic here
      this.showPopup = true;
      this.selectedProductName = product.product_name; // Set the selected product name
    }
  }
    
  onPopupClose() {
    this.showPopup = false;  // Close the popup
  }

  onSubscriptionSelected(subscriptionType: string) {
    this.selectedSubscriptionType = subscriptionType;
    console.log('Selected subscription type:', this.selectedSubscriptionType);
    // You can navigate or perform any other action here based on selected subscription type
    this.router.navigate(['/subscription', subscriptionType,{ product_name: this.selectedProductName }]);
    console.log(this.selectedProductName);
  }
      
  
}
