<html>
<head>
    <title>Dashboard</title>
</head>
<body class="bg-white dark:overflow-y-scroll dark:h-screen">
    <app-sidebar></app-sidebar>

    <!-- Main Content -->
    <main class="flex-1 h-full p-6 lg:ml-64 font-poppins">
        <!-- Cards Section -->
        <div class="mb-6 lg:mb-10">
            <h2 class="mb-6 text-lg font-medium text-gray-600">Activity Overview</h2>
            <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4">
                <!-- Example Cards -->
                <div class="relative flex items-center justify-between p-6 transition-transform duration-300 transform bg-white border-t-8 rounded-lg shadow-md hover:shadow-lg hover:scale-105 border-custom-teal">
                    <div>
                        <h2 class="text-lg font-medium text-gray-600 text-nowrap">Total Customer</h2>
                        <p class="mt-2 text-xl font-bold lg:text-2xl 2xl:text-3xl text-custom-gray font-times">200</p>
                    </div>
                    <div class="flex items-center justify-end">
                        <div class="p-4 rounded-full bg-gradient-to-r from-yellow-400 to-yellow-500">
                            <i class="text-4xl text-white fas fa-users"></i>
                        </div>
                    </div>
                </div>

                <div class="relative flex items-center justify-between p-6 transition-transform duration-300 transform bg-white border-t-8 rounded-lg shadow-md hover:shadow-lg hover:scale-105 border-custom-teal">
                    <div>
                        <h2 class="text-lg font-medium text-gray-600 text-nowrap">Total Active / Inactive Subscription</h2>
                        <p class="mt-2 text-xl font-bold lg:text-2xl 2xl:text-3xl text-custom-gray font-times">50 / 5</p>
                    </div>
                    <div class="flex items-center justify-end">
                        <div class="p-4 rounded-full bg-gradient-to-r from-green-400 to-green-500">
                            <i class="text-4xl text-white fas fa-calendar-days"></i>
                        </div>
                    </div>
                </div>

                <div class="relative flex items-center justify-between p-6 transition-transform duration-300 transform bg-white border-t-8 rounded-lg shadow-md hover:shadow-lg hover:scale-105 border-custom-teal">
                    <div>
                        <h2 class="text-lg font-medium text-gray-600">Delivered / Pending</h2>
                        <p class="mt-2 text-xl font-bold lg:text-2xl 2xl:text-3xl text-custom-gray font-times">60 / 20</p>
                    </div>
                    <div class="flex items-center justify-end">
                        <div class="p-4 rounded-full bg-gradient-to-r from-blue-400 to-blue-500">
                            <i class="text-4xl text-white fas fa-truck"></i>
                        </div>
                    </div>
                </div>

                <div class="relative flex items-center justify-between p-6 transition-transform duration-300 transform bg-white border-t-8 rounded-lg shadow-md hover:shadow-lg hover:scale-105 border-custom-teal">
                    <div>
                        <h2 class="text-lg font-medium text-gray-600">Total Sales</h2>
                        <p class="mt-2 text-xl font-bold lg:text-2xl 2xl:text-3xl text-custom-gray font-times">INR 5000</p>
                    </div>
                    <div class="flex items-center justify-end">
                        <div class="p-4 rounded-full bg-gradient-to-r from-red-400 to-red-500">
                            <i class="text-4xl text-white fas fa-clock"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       <!-- Chart Section -->
<div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
  <!-- Container for Sales vs Orders -->
  <div class="w-full p-6 bg-white rounded-lg shadow-md">
    <h2 class="mb-8 text-lg font-medium text-gray-600">Sales vs Orders</h2>
    <div class="relative" style="height: 0; padding-bottom: 50%;">
      <canvas #salesOrdersChart class="absolute inset-0 w-full h-full"></canvas>
    </div>
  </div>

  <!-- Container for Subscription Types -->
  <div class="w-full p-6 bg-white rounded-lg shadow-md">
    <h2 class="mb-8 text-lg font-medium text-gray-600">Subscription Types</h2>
    <div class="relative" style="height: 0; padding-bottom: 50%;">
      <canvas #subscriptionChart class="absolute inset-0 w-full h-full"></canvas>
    </div>
  </div>
</div>

        
    </main>
</body>
</html>
