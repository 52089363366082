import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService, Product } from '../api.service'; // Import Product here
import { SubscriptionDataService, SubscriptionData } from '../services/subscription-data.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'; // Import MatSnackBar
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  @ViewChild(HeaderComponent) headerComponent!: HeaderComponent;
  
  subscriptionData: SubscriptionData | null = null;

  showPopup = false;  // This is for the subscription benefits popup

  subscriptionType: string | null = null;
  subscriptionForm: FormGroup;
  products: Product[] = []; // Array to store products
  filteredQuantities: string[] = []; // Array to store filtered quantities
  minDateTime: string = '';

  selectedProductPrice: number | null = null; // Variable to store selected product price
  selectedProductMasterId: string | null = null; // Variable to store selected product master id

  showPrice = false; // New variable to manage price visibility
  invalidTimeError: boolean = false; // Custom flag for invalid time error
  subscriptionError: string | null = null;

  private routerSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService, // Inject the service
    private subscriptionDataService: SubscriptionDataService,
    private snackBar: MatSnackBar // Inject MatSnackBar
  ) {
   // Initialize form controls with disabled state
this.subscriptionForm = this.fb.group({
  product_name: ['', Validators.required],
  quantity: [{value: '', disabled: true}, Validators.required],
  start_date: ['', Validators.required],
});

// Update the quantity control's disabled state when product is selected
// Listen to changes in product selection
this.subscriptionForm.get('product_name')?.valueChanges.subscribe(selectedJuice => {
  this.updateQuantities(selectedJuice);
  this.checkToShowPrice(); // Call to check visibility of price
});

// Listen to changes in quantity selection
this.subscriptionForm.get('quantity')?.valueChanges.subscribe(selectedQuantity => {
  this.checkToShowPrice(); // Call to check visibility of price
});

 // Listen to route changes
 this.routerSubscription = this.router.events.subscribe(event => {
  if (event instanceof NavigationEnd) {
    // console.log('Navigated to:', event.urlAfterRedirects);
    if (!event.urlAfterRedirects.includes('/checkout')) {
      // console.log('Clearing subscription data because route changed');
      this.subscriptionDataService.clearSubscriptionData();
      
      setTimeout(() => {
        this.headerComponent.loadCart();
      }, 1000);
    }
  }
});

}

checkToShowPrice() {
  const selectedJuice = this.subscriptionForm.get('product_name')?.value;
  const selectedQuantity = this.subscriptionForm.get('quantity')?.value;

  if (selectedJuice && selectedQuantity) {
    this.showPrice = true; // Display price section
    this.fetchPrice(selectedJuice, selectedQuantity); // Fetch the price for selected juice and quantity
  } else {
    this.showPrice = false; // Hide price section
    this.selectedProductPrice = null; // Reset price
  }
}

fetchPrice(selectedJuice: string, selectedQuantity: string) {
  // Use the correct method from ApiService
  this.apiService.getProductPrice({
    product_name: selectedJuice,
    quantity: parseInt(selectedQuantity, 10), // Ensure quantity is a number
  }).subscribe(
    (priceResponse) => {
      this.selectedProductPrice = priceResponse.price;
    },
    (error) => {
      console.error('Error fetching price', error);
    }
  );
}


ngOnInit() {
  this.setMinDateTime(); // Set the minimum date on initialization

  // Load products on init first
  this.loadProducts(); 

  // After products are loaded, retrieve subscription type
  this.subscriptionType = this.route.snapshot.paramMap.get('type');

  // Listen for changes in the juice selection
  this.subscriptionForm.get('product_name')?.valueChanges.subscribe(selectedJuice => {
    this.updateQuantities(selectedJuice);
    // Clear quantity when juice changes
    this.subscriptionForm.get('quantity')?.setValue('');
  });

  // Listen for changes in the quantity selection
  this.subscriptionForm.get('quantity')?.valueChanges.subscribe(selectedQuantity => {
    const productControl = this.subscriptionForm.get('product_name');
    const quantityControl = this.subscriptionForm.get('quantity');

    if (!productControl?.value) {
      // Set an error for quantity when juice is not selected
      quantityControl?.setErrors({ juiceRequired: true });
      this.clearErrorsAfterDelay();
      quantityControl?.setValue(''); // Reset quantity
    } else {
      quantityControl?.setErrors(null); // Clear custom error if conditions are met
    }
  });

  // Validate start_date dynamically on input change
  this.subscriptionForm.get('start_date')?.valueChanges.subscribe((selectedDate: string) => {
    this.validateStartDate(selectedDate);
  });

  // Retrieve product_name from route parameters
  this.route.paramMap.subscribe(params => {
    const productName = params.get('product_name');
    if (productName) {
      this.subscriptionForm.get('product_name')?.setValue(productName);
      this.subscriptionForm.get('quantity')?.enable(); // Enable quantity field
      this.updateQuantities(productName); // Update quantities based on the product name
    }
  });
}

// Method to validate the start_date
validateStartDate(selectedDate: string) {
  if (!selectedDate) {
    this.invalidTimeError = false; // No error if the field is empty
    return;
  }

  const selectedDateTime = new Date(selectedDate);
  const minDateTime = new Date(this.minDateTime);

  // Custom logic to check if the selected time is valid
  if (selectedDateTime < minDateTime) {
    this.invalidTimeError = true; // Set error if invalid
    this.clearErrorsAfterDelay();
  } else {
    this.invalidTimeError = false; // Clear error if valid
  }
}

  ngOnDestroy() {
    // Unsubscribe from the router event when the component is destroyed
    // console.log('Checkout component destroyed');

    this.routerSubscription.unsubscribe();
  }

  // Method to set minimum date and time dynamically
  setMinDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    this.minDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  

  // Fetch products from the API
  loadProducts() {
    this.apiService.getProducts().subscribe((products) => {
      this.products = products;
      // console.log('Loaded Products:', this.products); // Debugging line
  
      // After products are loaded, check for product_name in route parameters
      this.route.paramMap.subscribe(params => {
        const productName = params.get('product_name');
        if (productName) {
          this.subscriptionForm.get('product_name')?.setValue(productName);
          this.subscriptionForm.get('quantity')?.enable(); // Enable quantity field
          this.updateQuantities(productName); // Update quantities based on the product name
        }
      });
    });
  }

  // Function to get unique product names
  getUniqueProductNames(): string[] {
    const productNames = this.products.map(product => product.product_name);
    return Array.from(new Set(productNames)); // Filter unique product names
  }

  // Function to update quantities based on selected juice
  updateQuantities(selectedJuice: string) {
  // console.log('Selected Juice:', selectedJuice);

    const filteredProducts = this.products.filter(product =>
      product.product_name === selectedJuice
    );
  // console.log('Filtered Products:', filteredProducts);

    const quantities = filteredProducts.map(product => product.quantity);
    this.filteredQuantities = Array.from(new Set(quantities));
  
  // console.log('Quantities:', this.filteredQuantities);

    const quantityControl = this.subscriptionForm.get('quantity');
    if (quantityControl) {
      if (quantities.length > 0) {
        quantityControl.enable();
      } else {
        quantityControl.disable();
        quantityControl.setValue(''); // Clear the value
      }
    }
  
    // Reset the price and id when quantity changes
    this.selectedProductMasterId = null;
    this.selectedProductPrice = null;
  }
  
  

  // Function to get formatted title
  getFormattedSubscriptionTitle(): string {
    switch (this.subscriptionType) {
      case 'daily':
        return 'DAILY';
      case 'alternate-day':
        return 'ALTERNATE DAY';
      case 'weekly':
        return 'WEEKLY';
      default:
        return '';
    }
  }

  handleQuantityClick() {
    const productControl = this.subscriptionForm.get('product_name');
    const quantityControl = this.subscriptionForm.get('quantity');
    
    if (quantityControl?.disabled && !productControl?.value) {
      // Set the custom error 'juiceRequired' if product is not selected and the quantity is disabled
      quantityControl?.setErrors({ juiceRequired: true });
      this.clearErrorsAfterDelay();
      // console.log('Quantity is disabled, juice is required.');
    } else if (productControl?.value) {
      // Remove the error if product is selected
      quantityControl?.setErrors(null);
    }
    
    // Manually mark the field as touched so the error message appears
    quantityControl?.markAsTouched();
  }   

  onSubmit() {
    this.invalidTimeError = false; // Reset error flag
    this.subscriptionError = null;

    // Mark all fields as touched to show validation errors
    this.subscriptionForm.markAllAsTouched();
  
    // Log the form status
    // console.log('Form Valid:', this.subscriptionForm.valid);
    // console.log('Quantity Value:', this.subscriptionForm.get('quantity')?.value);
  
    const quantityControl = this.subscriptionForm.get('quantity');
    const startDateControl = this.subscriptionForm.get('start_date');
    const selectedDateTime = new Date(this.subscriptionForm.get('start_date')?.value);
    const currentDateTime = new Date();
  
    // Check if the form is valid
    if (this.subscriptionForm.invalid) {
      // console.log('Form is invalid, errors:', this.subscriptionForm.errors);
  
      // Check if the quantity control is disabled or empty
      if (quantityControl?.disabled || !quantityControl?.value) {
        quantityControl?.setErrors({ required: true }); // Set required error only if relevant
      }
      this.clearErrorsAfterDelay(); // Clear errors after 3 seconds
    }

    // Check if the start_date field is empty
    if (!startDateControl?.value) {
      startDateControl?.setErrors({ required: true });
      this.clearErrorsAfterDelay(); // Clear errors after 3 seconds
    }
  
    // Validate that the start date is not in the past
    if (
      selectedDateTime.toDateString() === currentDateTime.toDateString() &&
      selectedDateTime < currentDateTime
    ) {
      this.invalidTimeError = true; // Set the custom error flag
      this.clearErrorsAfterDelay(); // Clear errors after 3 seconds
    }
  
    // Check overall form validity after custom validations
    if (this.subscriptionForm.invalid || this.invalidTimeError) {
      return; // Exit if the form is invalid
    }
  
    // Proceed with the rest of the submission logic if all validations pass
    const selectedJuice = this.subscriptionForm.get('product_name')?.value;
    const selectedQuantity = parseInt(quantityControl?.value, 10);
  
    // If product and quantity are selected, proceed to fetch the price
    if (selectedJuice && selectedQuantity) {
      // Step 1: Fetch product price and product_master_id first
      this.apiService.getProductPrice({
        product_name: selectedJuice,
        quantity: selectedQuantity,
      }).subscribe(
        (priceResponse) => {
          this.selectedProductPrice = priceResponse.price;
          this.selectedProductMasterId = priceResponse.product_master_id;
  
          const subscriptionData: SubscriptionData = {
            ...this.subscriptionForm.value,
            subscription_type: this.subscriptionType,
            price: this.selectedProductPrice,
            product_master_id: this.selectedProductMasterId,
          };
  
          if (!this.isLoggedIn()) {
            // Save the subscription data to local storage
            localStorage.setItem('subscriptionData', JSON.stringify(subscriptionData));
            // Save the intended redirect URL
            localStorage.setItem('redirectUrl', '/checkout');
            this.router.navigate(['/login']);
            return;
        }
  
          // Step 2: Now call checkSubscription API with valid product_master_id
          this.apiService.checkSubscription(subscriptionData).subscribe(
            (response) => {
              if (response.detail === "Subscription already exists") {
                this.subscriptionError = response.detail;
                this.clearErrorsAfterDelay(); // Clear errors after 3 seconds
              } else {
                // Step 3: Proceed to navigate to checkout if subscription does not exist
                this.subscriptionDataService.setSubscriptionData(subscriptionData);
                this.router.navigate(['/checkout']);
              }
            },
            (error) => {
              this.router.navigate(['/login']);
            }
          );
        },
        (error) => {
          console.log('Failed to fetch product price. Please try again.');
        }
      );
    } else {
      // If product or quantity is not selected, display error
      this.subscriptionForm.get('quantity')?.setErrors({ required: true });
      this.clearErrorsAfterDelay(); // Clear errors after 3 seconds
    }
  }
  
  clearErrorsAfterDelay() {
    setTimeout(() => {
      this.subscriptionError = null; // Clear subscription error message
      this.invalidTimeError = false; // Clear invalid time error
      const productControl = this.subscriptionForm.get('product_name');
      const quantityControl = this.subscriptionForm.get('quantity');
      const startDateControl = this.subscriptionForm.get('start_date');

      // Clear specific form control errors
      if (productControl?.errors) {
        productControl.setErrors(null);
      }
      if (quantityControl?.errors) {
        quantityControl.setErrors(null);
      }
      if (startDateControl?.errors) {
        startDateControl.setErrors(null);
      }
    }, 3000);
  }
  
  isLoggedIn(): boolean {
    // Check for the user's session or authentication state
    return !!localStorage.getItem('session_user_id');
  }
  
  openPopup(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.showPopup = true;
  }
  

  // Method to handle product name change
  onProductNameChange(): void {
    const selectedJuice = this.subscriptionForm.get('product_name')?.value;
    this.subscriptionForm.patchValue({
      quantity: '',
    });
    this.updateQuantities(selectedJuice); // Update quantities and fetch price
  }
  

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    config.verticalPosition = 'top'; // Position at the top
    config.horizontalPosition = 'center'; // Position at the center horizontally
  
    this.snackBar.open(message, 'Close', config);
  }
  
}