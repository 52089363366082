
<!-- header.html -->
<nav class="top-0 z-20 w-full bg-white border-b border-gray-500 dark:bg-white">
  <div class="max-w-screen-xl min-w-[100%] mx-auto flex flex-col lg:flex-row items-center justify-between h-32 p-2 xl:p-14">

    <!-- Logo - Always Visible -->
    <a href="/" class="flex items-center justify-center w-full logo-link lg:justify-start lg:w-1/3">
      <div class="w-full flex-shrink-0 flex justify-center items-center 2xl:justify-start h-[60px] md:h-[80px] lg:h-[57px] md:w-[600px] lg:w-[650px] ml-[10px] lg:ml-[-80px]">
        <img class="object-cover w-full h-full max-w-lg 2xl:max-w-2xl" src="../../assets/images/Nirdhara Website Icons-03.png" alt="Logo img" />
      </div>
    </a>
    <!-- Container for User Email and Links -->
    <div class="flex flex-col w-full">
      <!-- Row 1: User Email -->
      <div class="items-center justify-end hidden w-full lg:flex">
        <div *ngIf="isLoggedIn && userEmail" class="text-lg font-medium text-gray-900 font-poppins">
          Hi, {{ userEmail }}
        </div>
      </div>

      <!-- Row 2: Links, Logo, and Icons -->
      <div class="flex items-center justify-end w-full">


        <!-- Links and Icons - Visible on large screens -->
        <div class="items-center justify-end hidden space-x-3 lg:flex lg:w-2/3" *ngIf="showLinks">
          <ul class="flex space-x-6 xl:space-x-12 font-medium 3xl:pr-10 tracking-[2.5px] xl:tracking-[3.5px]">
            <li>
              <a routerLink="/juice-options" class="block py-4 text-xs xl:text-sm 2xl:text-lg 3xl:text-custom-font-[19px] 3xl:leading-custom-line-30 text-gray-900 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 font-poppins font-medium">
                JUICES
              </a>
            </li>
            <li class="relative group">
              <div class="block py-4 text-xs xl:text-sm 2xl:text-lg 3xl:text-custom-font-[19px] 3xl:leading-custom-line-30 text-gray-900 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 font-poppins font-medium cursor-pointer">
                INFORMATION
              </div>
              <ul class="absolute hidden w-48 p-2 -mt-2 space-y-2 border rounded-md shadow-md bg-custom-teal group-hover:block">
                <li>
                  <a class="block p-2 text-sm text-gray-900 rounded-md hover:text-blue-700">
                    OUR STORY
                  </a>
                </li>
                <li>
                  <a routerLink="/juice-delivery" class="block p-2 text-sm text-gray-900 rounded-md hover:text-blue-700">
                    JUICE DELIVERY
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a routerLink="/subscribe" class="block py-4 text-xs xl:text-sm 2xl:text-lg 3xl:text-custom-font-[19px] 3xl:leading-custom-line-30 text-gray-900 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 font-poppins font-medium">
                SUBSCRIBE
              </a>
            </li>
            <li>
              <a routerLink="/contact" class="block py-4 text-xs xl:text-sm 2xl:text-lg 3xl:text-custom-font-[19px] 3xl:leading-custom-line-30 text-gray-900 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 font-poppins font-medium">
                CONTACT
              </a>
            </li>
          </ul>
          <div class="flex items-center space-x-10">
            <a *ngIf="!hideIcons" routerLink="/cart" class="relative">
              <fa-icon [icon]="faShoppingCart" class="text-2xl text-gray-900"></fa-icon>
              <span class="absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-red-600 rounded-full bottom-6 left-5">{{ cartCount }}</span>
            </a>
            <div *ngIf="!hideIcons && !isLoggedIn" (click)="navigateToLogin()" role="button">
              <i class="text-2xl text-gray-900 cursor-pointer fa-solid fa-right-to-bracket"></i>
            </div>
            <div class="dropdown dropdown-end" *ngIf="isLoggedIn">
              <div tabindex="0" role="button">
                <fa-icon [icon]="faUser" class="text-2xl text-gray-900"></fa-icon>
              </div>
              <ul tabindex="0" class="menu menu-sm dropdown-content bg-custom-teal rounded-box z-[1] mt-3 w-44 p-4 font-poppins font-medium shadow">
                <!-- <li>
                  <a routerLink="/profile" class="flex items-center text-lg dark:text-black hover:bg-transparent hover:text-blue-700">
                    <i class="text-xl text-gray-900 fas fa-user-circle hover:text-blue-700"></i>&nbsp;
                    PROFILE
                  </a>
                </li> -->
                <li>
                  <a (click)="logout()" class="flex items-center text-lg dark:text-black hover:bg-transparent hover:text-blue-700">
                    <i class="text-xl text-gray-900 fas fa-right-from-bracket hover:text-blue-700"></i>&nbsp;
                    LOGOUT
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Burger Menu and Icons - Visible on small screens -->
<div class="relative flex items-center justify-between w-full lg:hidden" *ngIf="showLinks">
  <button (click)="toggleMenu()">
    <fa-icon [icon]="menuOpen ? faTimes : faBars" class="text-2xl text-gray-900"></fa-icon>
  </button>
  <div class="flex items-center space-x-4">
    <a *ngIf="!hideIcons" routerLink="/cart" class="relative">
      <fa-icon [icon]="faShoppingCart" class="text-2xl text-gray-900"></fa-icon>
      <span  class="absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-red-600 rounded-full bottom-6 left-5">{{ cartCount }}</span>
    </a>
    <!-- Show Login Icon if User is Not Logged In -->
    <div *ngIf="!hideIcons && !isLoggedIn" (click)="navigateToLogin()" role="button">
      <i class="text-2xl text-gray-900 cursor-pointer fa-solid fa-right-to-bracket"></i>
    </div>
    <div class="dropdown dropdown-end" *ngIf="isLoggedIn">
      <!-- Icon Trigger -->
      <div tabindex="0" role="button">
        <fa-icon [icon]="faUser" class="text-2xl text-gray-900"></fa-icon>
      </div>
      
      <!-- Dropdown Menu -->
      <ul
        tabindex="0"
          class="menu menu-sm dropdown-content bg-custom-teal rounded-box z-[1] mt-3 min-w-44 p-2 font-poppins font-medium shadow">
            <!-- User Email -->
            <li *ngIf="isLoggedIn && userEmail" class="py-1 text-sm font-medium text-center border-b text-custom-gray border-custom-gray">
              {{ userEmail }}
            </li>            
          <!-- <li>
            <a routerLink="/profile" class="flex items-center text-lg dark:text-black hover:bg-transparent hover:text-blue-700">
              <i class="text-lg text-custom-gray fas fa-user-circle hover:text-blue-700"></i>&nbsp;
              PROFILE
            </a>
          </li> -->
          <li>
            <a (click)="logout()" class="flex items-center text-lg dark:text-black hover:bg-transparent hover:text-blue-700">
              <i class="text-lg text-custom-gray fas fa-right-from-bracket hover:text-blue-700"></i>&nbsp;
              LOGOUT
            </a>
          </li>
        </ul>
    </div>
  </div>
  <!-- Links - Visible on small screens -->
  <div class="absolute p-2 rounded-lg mt-28 left-10 bg-custom-teal" [ngClass]="menuOpen ? 'block' : 'hidden'">
    <ul class="flex flex-col items-start justify-start font-medium">
      <li>
        <a routerLink="/juice-options" class="block px-4 py-2 text-sm font-medium text-gray-900 hover:bg-transparent hover:hover:text-blue-700 font-poppins">
          JUICES
        </a>
      </li>
      <li>
<!-- Information with Subheadings -->
<li class="relative">
  <!-- Main Heading with Chevron -->
  <div
    class="flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-900 cursor-pointer font-poppins"
    (click)="toggleInformationDropdown()"
  >
    <span class="mr-2">INFORMATION</span>
    <svg
      [ngClass]="infoDropdownOpen ? 'rotate-180' : 'rotate-0'"
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 h-5 transition-transform duration-200"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M5.293 7.707a1 1 0 011.414 0L10 11.414l3.293-3.707a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>
  </div>

  <!-- Subheadings -->
  <ul
    [ngClass]="infoDropdownOpen ? 'block' : 'hidden'"
    class="ml-4"
  >
    <li>
      <a
        routerLink="/our-story"
        class="block px-4 py-1 text-xs font-medium text-gray-900 hover:bg-transparent hover:text-blue-700 font-poppins"
      >
        OUR STORY
      </a>
    </li>
    <li>
      <a
        routerLink="/juice-delivery"
        class="block px-4 py-1 text-xs font-medium text-gray-900 hover:bg-transparent hover:text-blue-700 font-poppins"
      >
        JUICE DELIVERY
      </a>
    </li>
  </ul>
</li>

      <li>
        <a routerLink="/subscribe" class="block px-4 py-2 text-sm font-medium text-gray-900 hover:bg-transparent hover:hover:text-blue-700 font-poppins">
          SUBSCRIBE
        </a>
      </li>
      <li>
        <a routerLink="/contact" class="block px-4 py-2 text-sm font-medium text-gray-900 hover:bg-transparent hover:hover:text-blue-700 font-poppins">
          CONTACT
        </a>
      </li>
    </ul>
  </div>
  
</div>

  </div>
</nav>
