import { Component } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  registerForm: FormGroup;

  formSubmitted = false;
  isLoading = false; // Add this flag for loader
  passwordVisible: boolean = false;
  errorMessage: string = '';
  formMessage = ''; // Form-wide messages
  formMessageType: 'success' | 'error' | '' = ''; // Message type for styling

  otpSent: boolean = false; // Track if OTP has been sent
  otp: string = ''; // Store the entered OTP
  resendOtpCountdown: number = 120; // Initialize the countdown to 120

  // Inject ApiService and Router
  constructor(    private fb: FormBuilder,
                  private apiService: ApiService, private router: Router,private snackBar: MatSnackBar // Inject MatSnackBar
  ) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      otp: [''] // Add OTP field here
    });
  }

  onSubmit() {
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched(); // Highlight invalid fields
      this.autoClearErrorMessage();
      this.autoClearFormErrors(); // Clears the form errors after 3 seconds
      return;
    }
    // Convert email to lowercase before submitting
    const emailValue = this.registerForm.get('email')?.value;
    if (emailValue) {
      this.registerForm.patchValue({
        email: emailValue.toLowerCase()
      });
    }
    // Extract only the necessary fields
  const { email, mobile, password } = this.registerForm.value;
  const registrationData = { email, mobile, password };
    this.isLoading = true; // Start loading
      this.apiService.createUser(registrationData).subscribe(
        (response) => {
          this.isLoading = false; // Stop loading
          this.otpSent = true; // Set to true when OTP is sent
          this.setFormMessage('Check your email for the OTP!', 'success');
          this.startCountdown();
        },
        (error) => {
          this.isLoading = false; // Stop loading
          // Set the error message instead of using snackbar
          this.setFormMessage(error.error.detail || 'An error occurred during registration.', 'error');
        }
      );
  }

  setFormMessage(message: string, type: 'success' | 'error') {
    this.formMessage = message;
    this.formMessageType = type;
    this.autoClearErrorMessage();
  }

  autoClearErrorMessage() {
    setTimeout(() => {
      this.errorMessage = '';
      this.formMessage = '';
      this.formMessageType = '';
    }, 3000); // Clear after 3 seconds
  }

  autoClearFormErrors() {
    setTimeout(() => {
      Object.keys(this.registerForm.controls).forEach((key) => {
        const control = this.registerForm.get(key);
        control?.markAsUntouched(); // Clear the "touched" state
      });
    }, 3000); // 3 seconds
  }  

  showError(controlName: string): boolean {
    const control = this.registerForm.get(controlName);
    return (control?.invalid && control?.touched) || false;
  }  

  getErrorMessage(controlName: string): string {
    const control = this.registerForm.get(controlName);

    if (control?.errors) {
      if (control.errors['required']) return `${this.capitalize(controlName)} is required.`;
      if (control.errors['email']) return 'Enter a valid email address.';
      if (control.errors['pattern']) return 'Enter a valid 10-digit number.';
      if (control.errors['general']) {
        return 'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.';
      }
    }
    return '';
  }

  get password() {
    return this.registerForm.get('password');
  }

  validatePassword() {
    const password = this.password?.value || '';
  
    if (!password) {
      this.password?.setErrors({ required: true }); // Required error
    } else {
      const isValid = 
        password.length >= 8 &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /\d/.test(password) &&
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
  
      if (!isValid) {
        this.password?.setErrors({ general: true }); // General validation error
      } else {
        this.password?.setErrors(null); // No errors
      }
    }
  }

  private capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  
  onVerifyOtp() {
    const otpControl = this.registerForm.get('otp');
    const email = this.registerForm.value.email; // Ensure email is fetched from the form

    if (!otpControl?.value || otpControl?.invalid) {
      this.setFormMessage('Please enter a valid OTP.', 'error');
      return;
    }
  
    // Proceed with OTP verification
    this.apiService.verifyEmail(email,otpControl.value).subscribe(
      (response) => {
        this.openSnackBar('User registered successfully!', 'custom-snackbar');
        // Navigate to another page or mark registration as complete
        this.router.navigate(['/login']);
      },
      (error) => {
        this.setFormMessage(error.error.detail || 'Failed to verify OTP.', 'error');
      }
    );
  }
    
  handleSendOtp(): void {
    const email = this.registerForm.value.email; // Ensure email is fetched from the form

    if (this.resendOtpCountdown === 0) {
      this.apiService.sendOtp(email).subscribe({
        next: () => {
          this.startCountdown();
          this.setFormMessage('OTP has been resent!', 'success');
        },
        error: (error) => {
          this.setFormMessage(error.error.detail || 'Failed to send OTP.', 'error');
        },
      });
    }
  }

  startCountdown(): void {
    this.resendOtpCountdown = 120; // Reset countdown to 120 seconds
    const interval = setInterval(() => {
      this.resendOtpCountdown--;
      if (this.resendOtpCountdown <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    config.verticalPosition = 'top'; // Position at the top
    config.horizontalPosition = 'center'; // Position at the center horizontally
  
    this.snackBar.open(message, 'Close', config);
  }
  
}
