import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService, Product } from '../api.service'; // Import the Product interface

@Component({
  selector: 'app-product-popup',
  templateUrl: './product-popup.component.html',
  styleUrls: ['./product-popup.component.css']
})
export class ProductPopupComponent {
  @Input() product: Product = {
    product_master_id: '',
    product_name: '',
    quantity: '',
    price: null, // Initialize price as null
    product_description: '', // Added this
    juice_name: '', // Added this
    product_images: [], // Added this
  };
  
  @Input() editMode: boolean = false;

  @Output() productUpdated = new EventEmitter<Product>();
  @Output() closePopupEvent = new EventEmitter<void>();

  productForm: FormGroup;
  detail: string = ''; // For displaying messages
  messageType: 'success' | 'error' | null = null; // New property to track message type
  imagePreviews: string[] = []; // Array to hold image preview URLs
  showFileInput: boolean = false; // New property to control file input visibility

  constructor(private apiService: ApiService) {
    this.productForm = new FormGroup({
      product_name: new FormControl(this.product.product_name, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z\s‘'&]+$/) // Allow letters, spaces, apostrophes, and '&'
      ]),      
      quantity: new FormControl(this.product.quantity, [
        Validators.required,
        Validators.pattern(/^\d*\.?\d+$/) // Pattern for numeric values including float
      ]),
      price: new FormControl(this.product.price, [
        Validators.required,
        Validators.pattern(/^\d*\.?\d+$/) // Pattern for numeric values including float
      ]),
      product_description: new FormControl(this.product.product_description, Validators.required),
      juice_name: new FormControl(this.product.juice_name, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z\s]+$/) // Pattern for letters and spaces only
      ]),
      product_images: new FormControl([], Validators.required) // For file input
    });

    this.productForm.get('product_name')?.valueChanges.subscribe(value => {
      if (value) {
        this.productForm.patchValue({
          product_name: this.toTitleCase(value)
        }, { emitEvent: false });
      }
    });
  }

  ngOnChanges() {
    if (this.editMode && this.product) {
      console.log('Entering edit mode with product:', this.product); // Log the product details
  
      // Patch the form values with the product details
      this.productForm.patchValue({
        product_name: this.product.product_name,
        quantity: this.product.quantity,
        price: this.product.price,
        product_description: this.product.product_description,
        juice_name: this.product.juice_name,
        product_images: this.product.product_images.map(image => image.image_url) // Initialize with existing image URLs
      });
  
      // Populate image previews if the product contains images
      this.imagePreviews = this.product.product_images.map((image) => image.image_url);
      
      console.log('Initialized product_images in form:', this.productForm.get('product_images')?.value); // Log the initialized product_images
    }
  }  

  closePopup() {
    this.closePopupEvent.emit();
  }

  onSubmit() {
    if (this.productForm.invalid) {
      this.productForm.markAllAsTouched(); // Highlight invalid fields
      this.autoClearFormErrors(); // Clears the form errors after 3 seconds
      return;
    } else {
      const formData = new FormData();
      const productData = { ...this.productForm.value }; // Extract form values
  
      if (this.editMode) {
        // Send product_update for edit
        formData.append(
          'product_update',
          JSON.stringify({
            product_master_id: this.product.product_master_id, // Include master ID for updating
            product_name: productData.product_name,
            product_description: productData.product_description,
            juice_name: productData.juice_name,
            quantity: productData.quantity,
            price: productData.price,
            updated_by: 'string', // Add as required
          })
        );
      } else {
        // Send product for create
        formData.append(
          'product',
          JSON.stringify({
            product_name: productData.product_name,
            product_description: productData.product_description,
            juice_name: productData.juice_name,
            quantity: productData.quantity,
            price: productData.price,
            created_by: 'string', // Add as required
          })
        );
      }
  
      // Append each file in product_images only if they exist
      const productImages = this.productForm.value.product_images;
      if (productImages && productImages.length > 0) {
        for (let i = 0; i < productImages.length; i++) {
          // Ensure that the file is a valid Blob before appending
          if (productImages[i] instanceof File) {
            formData.append('product_images', productImages[i], productImages[i].name);
          }
        }
      }
  
      const updatedProduct: Product = { ...this.product, ...this.productForm.value };
      if (this.editMode) {
        this.apiService.updateProduct(formData).subscribe(
          () => {
            this.detail = 'Product updated successfully';
            this.messageType = 'success'; // Set message type to success
            this.clearMessage(); // Clear message after 3 seconds
  
            setTimeout(() => {
              this.productUpdated.emit(); // Notify parent to refresh products
            }, 1000); // 3 seconds
          },
          (error) => {
            this.detail = error?.error?.detail || 'Error updating product';
            this.messageType = 'error'; // Set message type to error
            this.clearMessage(); // Clear message after 3 seconds
          }
        );
      } else {
        this.apiService.createProduct(formData).subscribe(
          () => {
            this.detail = 'Product added successfully';
            this.messageType = 'success'; // Set message type to success
            this.clearMessage(); // Clear message after 3 seconds
  
            setTimeout(() => {
              this.productUpdated.emit(); // Notify parent to refresh products
            }, 1000); // 3 seconds
          },
          (error) => {
            this.detail = error?.error?.detail || 'Error adding product';
            this.messageType = 'error'; // Set message type to error
            this.clearMessage(); // Clear message after 3 seconds
          }
        );
      }
    }
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0]; // Get the first file only
      this.imagePreviews = [URL.createObjectURL(file)]; // Generate a preview for the selected file
  
      // Update the form control with the new file
      this.productForm.patchValue({
        product_images: [file] // Store the file in an array
      });
    }
  }
  
  removeImage(index: number): void {
    this.imagePreviews.splice(index, 1); // Remove image preview from the array
  
    // Update the product_images form control
    this.productForm.patchValue({
      product_images: [] // Clear the file input
    });
  
    // Clear the file input field
    const fileInput = document.getElementById('product_images') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Reset file input
    }
  }
  

  autoClearFormErrors() {
    setTimeout(() => {
      Object.keys(this.productForm.controls).forEach((key) => {
        const control = this.productForm.get(key);
        control?.markAsUntouched(); // Clear the "touched" state
      });
    }, 3000); // 3 seconds
  }

  clearMessage() {
    setTimeout(() => {
      this.detail = '';
      this.messageType = null; // Reset message type
    }, 3000); // 3 seconds
  }

  showError(controlName: string): boolean {
    const control = this.productForm.get(controlName);
    return (control?.invalid ?? false) && (control?.touched ?? false);
  }  

  getErrorMessage(controlName: string): string {
    const control = this.productForm.get(controlName);
  
    if (control?.errors) {
      if (control.errors['required']) {
        return `${this.capitalize(controlName)} is required.`;
      }
      if (control.errors['pattern']) {
        switch (controlName) {
          case 'product_name':
            return `Product Name must contain only letters and spaces.`;
          case 'quantity':
            return `Quantity must be a valid number.`;
          case 'price':
            return `Price must be a valid number.`;
          case 'juice_name':
            return `Juice Name must contain only letters and spaces.`;
        }
      }
    }
    return '';
  }  

  private capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    });
  }

}
