import { Component } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SubscriptionDataService } from '../services/subscription-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  email: string = '';
  password: string = '';
  passwordVisible: boolean = false;
  errorMessage: string = '';  // Variable to hold error message

  constructor(
    private apiService: ApiService,
    private router: Router,
    private snackBar: MatSnackBar,
    private subscriptionDataService: SubscriptionDataService,
  ) {}

  ngOnInit(): void {
    // Clear specific keys from localStorage
    localStorage.removeItem('session_user_id');
    localStorage.removeItem('session_role_id');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('cartCount');
  }
  
  onSubmit() {

      // Check if email or password is empty
  if (!this.email.trim() || !this.password.trim()) {
    this.errorMessage = 'Email and password are required!';
    this.autoClearErrorMessage(); // Automatically clear the error message after a few seconds
    return; // Stop execution if validation fails
  }

    const loginData = {
      email: this.email.toLowerCase(),
      password: this.password,
    };

    this.apiService.login(loginData).subscribe({
      next: (response) => {
        if (response.message === 'Login successful') {
          localStorage.setItem('isLoggedIn', 'true');

          // Store the email in localStorage
          localStorage.setItem('userEmail', this.email);
          
          // Check session_role_id
          const sessionRoleId = response.session_role_id;
          if (sessionRoleId === 'DR6OBr8M' || sessionRoleId === 'm4I9bCyx') {
            // Navigate to '/product' route if role matches
            this.router.navigate(['/product']);
          } else {
            // Redirect to the intended URL or fallback to default
            const redirectUrl = localStorage.getItem('redirectUrl') || '/';
            localStorage.removeItem('redirectUrl');
            // Retrieve subscription data from local storage
            const subscriptionData = localStorage.getItem('subscriptionData');
            if (subscriptionData) {
                this.subscriptionDataService.setSubscriptionData(JSON.parse(subscriptionData));
                localStorage.removeItem('subscriptionData'); // Clear the subscription data after use
            }
            this.router.navigate([redirectUrl]);
          }

          // console.log('Login successful!', 'custom-snackbar');
        } else {
          // Handle unexpected response (e.g., incorrect credentials)
          this.errorMessage = 'Login failed: ' + response.message;
          this.autoClearErrorMessage();  // Call method to clear the error message
        }
      },
      error: (error) => {
        // Handle HTTP error
        const errorMessage = error.error.detail || 'Login failed!';
        this.errorMessage = errorMessage;  // Display error message below the password field
        this.autoClearErrorMessage();  // Call method to clear the error message
      },
    });
  }

  // Method to automatically clear error message after 3 seconds
  autoClearErrorMessage() {
    setTimeout(() => {
      this.errorMessage = '';  // Clear error message after 3 seconds
    }, 3000);  // 3000 milliseconds = 3 seconds
  }

openSnackBar(message: string, panelClass: string) {
  const config = new MatSnackBarConfig();
  config.duration = 3000;
  config.panelClass = [panelClass];
  config.verticalPosition = 'top'; // Position at the top
  config.horizontalPosition = 'center'; // Position at the center horizontally

  this.snackBar.open(message, 'Close', config);
}

}