<body class="dark:bg-white">
<app-above-header></app-above-header>
<app-header></app-header>
<main class="flex flex-col py-0 lg:flex-row">
  <!-- Left Section with Image -->
  <section
    class="w-full lg:w-[55%] h-[500px] lg:h-[750px] xl:h-[730px] 2xl:h-[800px]"
  >
    <div class="w-full h-full orange-bg-image">
      <!-- Add your image here -->
      <img class="object-cover w-full h-full" src="../../assets/images/benigno-hoyuela-E1ZwXiBD7LA-unsplash.jpg" alt="Orange img" />
    </div>
  </section>

  <!-- Right Section with Dynamic Content and Form -->
  <section
    class="w-full lg:w-[45%] flex flex-col text-left pl-10 3xl:pl-16 pr-4 lg:pr-8"
  >
    <h1 class="mt-5 text-3xl lg:text-4xl 2xl:text-custom-font-45 2xl:leading-custom-line-60 text-custom-gray sm:mt-12">
      <span class="font-times">{{ getFormattedSubscriptionTitle() }}</span>
      <span class="italic font-times"> subscription</span>
    </h1>

    <p class="mt-2 text-lg font-bold 2xl:text-custom-font-20 2xl:leading-custom-line-30 font-poppins text-custom-gray ">
      FROM INR 100
    </p>
    <p class="text-[15px] leading-custom-line-30 3xl:text-custom-font-20 2xl:leading-custom-line-38 text-custom-gray  mt-5 font-lato pr-0 3xl:pr-12">
      <!-- Dynamic content based on subscription type -->
      <ng-container *ngIf="subscriptionType === 'daily'">
        With our Daily Subscription, you can select your favorite juices and
        have them delivered to your doorstep every day. Enjoy the convenience of
        fresh juice daily.
      </ng-container>
      <ng-container *ngIf="subscriptionType === 'alternate-day'">
        Our Alternate Day Subscription allows you to choose your preferred
        juices and receive deliveries every other day. Perfect for those who
        enjoy fresh juice regularly but not necessarily every day.
      </ng-container>
      <ng-container *ngIf="subscriptionType === 'weekly'">
        Customize your juice experience with our Weekly Subscription. Choose
        your favorite juices and select the specific days you want them
        delivered. Enjoy flexibility and personalization to fit your schedule.
      </ng-container>
    </p>

    <form
    [formGroup]="subscriptionForm"
    (ngSubmit)="onSubmit()"
    class="mt-10 space-y-5 3xl:mt-7"
  >
    <div>
      <label
        for="product_name"
        class="block text-sm 2xl:text-lg font-poppins font-bold text-[#2F2C2B] "
        >SELECT YOUR JUICE</label
      >
      <select
        (change)="onProductNameChange()"
        id="product_name"
        formControlName="product_name"
        class="mt-2 border-2 border-[#1D1A1A] text-custom-gray dark:bg-white py-1 xl:py-2.5 3xl:py-2 px-2 font-poppins text-sm 2xl:text-lg text-left w-64 2xl:w-[292px] tracking-[3.6px]"
      >
        <option value="" disabled class="">SELECT YOUR JUICE</option>
        <option
          *ngFor="let productName of getUniqueProductNames()"
          [value]="productName"
          class=""
        >
          {{ productName }}
        </option>
      </select>
          <!-- Error Message -->
    <div *ngIf="subscriptionForm.get('product_name')?.touched && subscriptionForm.get('product_name')?.errors?.['required']" class="mt-1 text-sm text-red-600">
      Please select a juice.
    </div>
    </div>

    <div>
      <label
        for="quantity"
        class="block text-sm 2xl:text-lg font-poppins font-bold text-[#2F2C2B] "
        >CHOOSE YOUR QUANTITY</label
      >
      <div (click)="handleQuantityClick()" class="relative">
        <select
          id="quantity"
          formControlName="quantity"
          class="mt-2 border-2 border-[#1D1A1A] text-custom-gray dark:bg-white  py-1 xl:py-2.5 3xl:py-2 px-2 font-poppins text-sm 2xl:text-lg text-left w-40 tracking-[3.6px]"
          [ngStyle]="{
            'pointer-events': subscriptionForm.get('quantity')?.disabled
              ? 'none'
              : 'auto'
          }"
        >
          <option value="" disabled>QUANTITY</option>
          <option
            *ngFor="let quantity of filteredQuantities"
            [value]="quantity"
            class=""
          >
            {{ quantity }} ML
          </option>
        </select>
      </div>
      <!-- Error Messages -->
      <div *ngIf="subscriptionForm.get('quantity')?.touched">
        <div *ngIf="subscriptionForm.get('quantity')?.errors?.['required']" class="mt-1 text-sm text-red-600">
          Please select a quantity.
        </div>
        <div *ngIf="subscriptionForm.get('quantity')?.errors?.['juiceRequired']" class="mt-1 text-sm text-red-600">
          Please select a juice first to add quantity.
        </div>
      </div>
    </div>

    <div *ngIf="showPrice">
      <label for="price" class="block text-sm 2xl:text-lg font-poppins font-bold text-[#2F2C2B]">PRICE</label>
      <input
        type="text"
        id="price"
        [value]="'INR ' + selectedProductPrice"
        disabled
        class="mt-2 border-2 border-[#1D1A1A] text-custom-gray dark:bg-white py-1 xl:py-2.5 3xl:py-2 px-2 font-poppins text-sm 2xl:text-lg text-left w-40 tracking-[3.6px] cursor-not-allowed"
      />
    </div>
    

    <div>
      <label for="start_date" class="block text-sm 2xl:text-lg font-poppins font-bold text-[#2F2C2B]">
        SELECT YOUR START DATE
      </label>
      <input
      type="datetime-local"
      id="start_date"
      formControlName="start_date"
      class="mt-2 border-2 border-[#1D1A1A] text-custom-gray bg-white dark:bg-white dark:text-black py-1 xl:py-2.5 3xl:py-2 px-4 font-poppins text-sm 2xl:text-lg text-left w-64 lg:w-64 adaptive-icon"
      [attr.min]="minDateTime"
    />
        <!-- Required field error -->
        <div
          *ngIf="subscriptionForm.get('start_date')?.touched && subscriptionForm.get('start_date')?.errors?.['required']"
          class="mt-1 text-sm text-red-600"
        >
          Please select a start date and time.
        </div>
        <!-- Invalid time error -->
        <div
          *ngIf="invalidTimeError"
          class="mt-1 text-sm text-red-600"
        >
          Selected time has passed. Please choose a valid time.
        </div>
    </div>
    
    <!-- Display subscription error message -->
    <div *ngIf="subscriptionError" class="mt-1 text-sm text-red-600">
      {{ subscriptionError }}
    </div>

    <button
      type="submit"
      class="mt-5 border-2 border-[#1D1A1A] bg-custom-gray hover:bg-gray-900 text-custom-text-color-button  py-1 2xl:py-2 px-4 font-poppins text-sm 2xl:text-lg text-left w-64 2xl:w-80"
    >
    <span class="tracking-[3.6px]">START SUBSCRIPTION</span>
    <p
        class="flex items-center justify-between text-sm 2xl:text-lg font-times text-custom-text-color-button "
      >
        <span>Pause or cancel anytime.</span>
        <span class="ml-2 text-custom-text-color-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            class="inline h-7 w-7"
            (click)="openPopup($event)"
            >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0Zm-9-3.75h.008v.008H12V8.25Z"
            />
          </svg>
        </span>
      </p>
    </button>
  </form>

    <h2 class="mt-4 text-3xl lg:text-4xl 2xl:text-5xl font-times text-custom-gray lg:mt-16 3xl:mt-12">
      <span class="font-times">Check our</span>
      <span class="italic font-times"> delivery</span>
      <span class="font-times"> zones.</span>
    </h2>
  </section>
</main>
<app-subscription-benefits
  *ngIf="showPopup"
  (closePopup)="showPopup = false"
></app-subscription-benefits>
<!-- New Section -->
<section
  class="flex flex-col items-start justify-between p-4 mt-10 lg:flex-row lg:p-8"
>
  <!-- Left Side -->
  <div class="w-full pl-6 mt-5 lg:w-3/5">
  
    <h3 class="text-3xl text-left lg:text-4xl 2xl:text-5xl font-times text-custom-gray">
      <span class="font-times">Terms and</span>
      <span class="italic font-times"> conditions.</span>
    </h3>
    <p [ngClass]="{
      'xl:max-w-[430px] 3xl:max-w-xl': subscriptionType === 'daily',
      'max-w-lg': subscriptionType === 'alternate-day',
      'max-w-[490px]': subscriptionType === 'weekly'
    }" 
    class="text-[15px] leading-custom-line-30 3xl:text-custom-font-20 2xl:leading-custom-line-38 text-custom-gray mt-8 font-lato"
  >
    <!-- Dynamic content based on subscription type -->
    <ng-container *ngIf="subscriptionType === 'daily'">
      With our Daily Subscription, you can select your favorite juices and
      have them delivered to your doorstep every day. Enjoy the convenience of
      fresh juice daily.
    </ng-container>
    <ng-container *ngIf="subscriptionType === 'alternate-day'">
      Our Alternate Day Subscription allows you to choose your preferred
      juices and receive deliveries every other day. Perfect for those who
      enjoy fresh juice regularly but not necessarily every day.
    </ng-container>
    <ng-container *ngIf="subscriptionType === 'weekly'">
      Customize your juice experience with our Weekly Subscription. Choose
      your favorite juices and select the specific days you want them
      delivered. Enjoy flexibility and personalization to fit your schedule.
    </ng-container>
  </p>
  
  </div>

  <!-- Right Side with Image -->
  <div class="w-full mt-8 lg:w-3/5 lg:mt-0">
    <section class="w-full h-full">
        <!-- Add your image here -->
        <img class="object-cover w-full h-full" src="../../assets/images/Nirdhara Website Icons-01.png" alt="Orange img" />
    </section>
  </div>
</section>
</body>
<app-footer></app-footer>