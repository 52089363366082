<!-- cart component goes here -->

<app-above-header></app-above-header>
<app-header></app-header>
<body class="bg-white dark:h-screen dark:overflow-y-scroll">
<div class="container mx-auto p-4 max-w-[1200px]">
  <ng-container *ngIf="loading && isLoggedIn; else cartContent">
    <div class="flex items-center justify-center h-64">
      <p class="text-lg text-custom-gray">Loading your cart...</p>
    </div>
  </ng-container>
  <ng-template #cartContent>
  <div
    *ngIf="!isEmpty; else emptyCart"
    class="grid grid-cols-1 gap-4 lg:grid-cols-3"
  >
    <div class="col-span-2">
      <div class="p-4 mt-4 border rounded">
        <div class="flex justify-between pb-4 border-b-2 border-custom-teal">
          <h2 class="text-2xl font-medium text-custom-gray font-times">Shopping Cart</h2>
          <!-- Select All Toggle -->
          <div class="flex items-center">
            <label
              for="toggleThree"
              class="flex items-center cursor-pointer select-none text-custom-gray"
            >
              <label class="mr-2 font-times">{{
                selectAll ? "Deselect all" : "Select all"
              }}</label>
              <div class="relative">
                <input
                  type="checkbox"
                  id="toggleThree"
                  class="sr-only peer"
                  [(ngModel)]="selectAll"
                  (change)="toggleSelectAll()"
                />
                <div
                  class="block w-12 h-6 bg-gray-300 rounded-full"
                ></div>
                <div
                  class="absolute flex items-center justify-center w-5 h-5 transition bg-white  rounded-full dot  left-1 top-0.5 peer-checked:translate-x-full peer-checked:bg-custom-gray"
                >
                  <!-- Tick Icon -->
                  <svg
                    *ngIf="selectAll"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.4"
                    />
                  </svg>

                  <!-- Cross Icon -->
                  <svg
                    *ngIf="!selectAll"
                    class="w-4 h-4 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </div>
              </div>
            </label>
          </div>
        </div>

        <!-- Items List -->
        <div
          *ngFor="let item of cartItems"
          class="grid items-center gap-4 p-4 pb-4 mt-5 mb-4 transition duration-300 border border-gray-200 rounded-lg shadow-sm sm:grid-cols-3 sm:gap-12 sm:mt-0 hover:bg-gray-50 sm:border-none sm:shadow-none"
        >
         <!-- Checkbox and Image Column -->
<div class="flex items-center justify-between sm:col-span-1 sm:justify-start sm:space-x-6">
    <!-- Checkbox aligned to the start (left side) on all screens -->
    <input
      type="checkbox"
      class="w-4 h-4 form-checkbox"
      [(ngModel)]="item.selected"
      (change)="calculateSubtotal()"
    />
    
    <!-- Image centered on mobile screens and left-aligned on larger screens -->
    <div class="flex justify-center flex-grow sm:justify-start">
      <div class="w-40 h-40 bg-gray-100 juice-bottle-image"></div>
    </div>
  </div>
  

          <!-- Product Details Column -->
          <div class="mt-4 text-center sm:col-span-1 sm:mt-0 sm:text-left font-poppins">
            <h3 class="mb-1 text-xl font-medium text-custom-gray">
              {{ item.product_name }}
            </h3>
            <p class="mb-2 text-lg text-gray-600 text-nowrap">
              {{
                item.subscription_type === "daily"
                  ? "Daily Subscription"
                  : item.subscription_type === "alternate-day"
                  ? "Alternate Day Subscription"
                  : item.subscription_type === "weekly"
                  ? "Weekly Subscription"
                  : "Unknown Subscription Type"
              }}
              | {{ item.quantity }} ML
            </p>
            <p class="text-xl font-bold text-red-600">₹{{ item.price }}</p>
          </div>

          <!-- Delete Button Column -->
          <div
            class="flex justify-center mt-4 sm:col-span-1 sm:mt-0 sm:justify-end"
          >
            <button
              (click)="deleteItem(item.cart_id)"
              class="flex items-center space-x-2 text-custom-gray hover:underline font-poppins"
            >
              <i class="text-lg fas fa-trash-alt"></i>
              <span>Delete</span>
            </button>
          </div>
        </div>

        <hr class="my-4" />

        <div
          *ngIf="selectedItemsCount > 0; else noItemsSelected1"
          class="flex items-center justify-end mb-3 text-xl font-poppins"
        >
          <span class="text-gray-700 "
            >Subtotal ({{ selectedItemsCount }} items):</span
          >&nbsp;
          <span class="font-medium text-gray-900">₹{{ subtotal }}</span>
        </div>

        <ng-template #noItemsSelected1>
          <div class="flex items-center justify-end mb-3 text-lg text-gray-700 font-poppins">
            No items selected
          </div>
        </ng-template>
      </div>
    </div>
    <!-- Cart Summary Section -->
    <div class="col-span-1">
      <div class="p-5 mt-4 border rounded">
        <div
          class="flex items-center justify-between pb-3 mb-4 border-b-2 border-custom-teal"
        >
          <h2 class="text-2xl font-medium font-times text-custom-gray">Cart Summary</h2>
        </div>
        <!-- Summary Details Go Here -->

        <!-- Subtotal and Total Display -->
        <!-- Second Subtotal Display with Unique Template -->
        <div
          *ngIf="selectedItemsCount > 0; else noItemsSelected2"
          class="flex items-center justify-between mb-3 text-xl font-poppins"
        >
          <span class="text-gray-700 "
            >Subtotal ({{ selectedItemsCount }} items):</span
          >&nbsp;
          <span class="font-bold text-red-600">₹{{ subtotal }}</span>
        </div>

        <ng-template #noItemsSelected2>
          <div
            class="flex items-center justify-start mb-3 text-lg text-gray-700 font-poppins"
          >
            No items selected
          </div>
        </ng-template>

        <div class="flex items-center justify-center mt-5">
          <button
          (click)="proceedToCheckout()"
            class="px-2 py-1 mb-4 text-sm font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-poppins lg:text-lg w-fit"
          >
            Proceed to checkout
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
  <ng-template #emptyCart>
    <div class="flex flex-col items-center justify-center mt-20 font-times">
      <div class="empty-cart-image w-60 h-60 sm:w-80 sm:h-80"></div>
      <h2 class="mb-2 text-2xl font-medium text-gray-700">
        Your Cart is Empty
      </h2>
      <p class="mb-5 text-sm text-gray-600 sm:text-lg">
        Looks like you haven't added anything to your cart yet.
      </p>
      <button
        (click)="redirectToShop()"
        class="px-4 py-2 font-medium tracking-wider bg-custom-gray hover:bg-gray-900 text-custom-text-color-button"
      >
        Continue Shopping
      </button>
    </div>
  </ng-template>
</div>

<!-- Cart Component Template -->
<div *ngIf="showPopup" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
  <div class="relative w-[90%] p-4 bg-white shadow-xl rounded-xl sm:p-8 sm:max-w-xl">
    <!-- Close Button (Icon) -->
    <button (click)="closePopup()" class="absolute text-gray-500 transition duration-300 ease-in-out top-2 right-2 hover:text-gray-800">
      <!-- Close Icon -->
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-7 h-7">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <!-- Popup Content -->
    <h3 class="mb-1 text-xl font-bold text-center text-gray-800 sm:text-2xl font-times">Your Selected Items</h3>
    <p class="pb-4 mb-6 text-sm text-center text-gray-800 border-b-2 sm:text-lg font-times border-custom-teal">Please Select Start Date Before Checkout!</p>

    <!-- Display only selected items -->
    <div *ngFor="let item of selectedItems; let i = index" class="flex flex-col items-start mb-6 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 font-poppins">
      <span class="text-sm font-medium text-custom-gray sm:text-lg">
        {{ item.product_name }} - {{
          item.subscription_type === "daily"
            ? "Daily Subscription"
            : item.subscription_type === "alternate-day"
            ? "Alternate Day Subscription"
            : item.subscription_type === "weekly"
            ? "Weekly Subscription"
            : "Unknown Subscription Type"
        }}
      </span>
    
      <div class="relative">
        <input type="datetime-local" [(ngModel)]="item.start_date" 
          class="border-2 border-[#1D1A1A] bg-white text-custom-gray py-2 px-4 font-poppins text-xs sm:text-lg w-full sm:w-64 adaptive-icon" 
          [attr.min]="minDateTime"
          (input)="onStartDateChange($event, i)"
        />
        
        <!-- Display error message with a fixed container height -->
        <div *ngIf="invalidTimeErrors[i]" class="relative left-0 text-sm text-red-600 mt-1 max-w-[220px]">
          Selected time has passed. Please choose a valid time.
        </div>
      </div>
    </div>
    
    <!-- Display error message if no start date is selected -->
    <div *ngIf="missingStartDateError" class="mb-4 text-sm text-center text-red-600">
      Please select a start date for all items before proceeding to checkout.
    </div>
    <!-- Action Buttons -->
    <div class="flex justify-center space-x-4">
      <button (click)="confirmCheckout()" 
        class="px-2 py-1 text-xs font-normal tracking-widest bg-custom-gray hover:bg-gray-900 text-custom-text-color-button lg:font-medium lg:py-2 lg:px-4 font-poppins sm:text-sm lg:text-lg w-fit"
      >Checkout</button>
    </div>
  </div>
</div>

</body> 
