import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Fetch session_role_id from localStorage
    const sessionRoleId = localStorage.getItem('session_role_id');

    // List of restricted routes
    const restrictedRoutes = [
      '/',
      '/subscribe',
      '/subscription',
      '/checkout',
      '/cart',
      '/T&C',
      '/refund_policy',
      '/privacy_policy',
      '/return_policy',
      '/shipping_policy',
      '/juice-delivery',
      '/about-us',
      '/juice-options'
    ];

// Check if the user is trying to access a restricted route
if (restrictedRoutes.includes(state.url)) {
  // Check if the user has a restricted role
  if (sessionRoleId === 'DR6OBr8M' || sessionRoleId === 'm4I9bCyx') {
    // Redirect to login page if they have the restricted role
    this.router.navigate(['/login'], { state: { returnUrl: state.url } });
    return false; // Deny access
  }
}

    // If not a restricted route or valid role, allow access
    return true;
  }
}