import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api.service';

export interface SubscriptionData {
  product_name: string;
  quantity: string;
  start_date: Date;
  subscription_type: string;
  price: number;
  product_master_id: string; // Add this line
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionDataService {
  private subscriptionData = new BehaviorSubject<SubscriptionData | null>(this.loadInitialData());
  private isSubscriptionCreated = false; // Track if subscription is created

  constructor(private apiService: ApiService) {}  // Inject ApiService

  private loadInitialData(): SubscriptionData | null {
    const data = localStorage.getItem('subscriptionData');
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        if (parsedData.start_date) {
          parsedData.start_date = new Date(parsedData.start_date); // Convert back to Date
        }
        return parsedData as SubscriptionData;
      } catch (error) {
        // console.error('Error parsing subscription data:', error);
      }
    }
    return null;
  }
  

  setSubscriptionData(data: SubscriptionData): void {
    // Convert Date to ISO string for storage
    const dataToStore = {
      ...data,
      start_date: data.start_date instanceof Date ? data.start_date.toISOString() : data.start_date
    };
    this.subscriptionData.next(data);
    localStorage.setItem('subscriptionData', JSON.stringify(dataToStore));
  }

  getSubscriptionData() {
    return this.subscriptionData.asObservable();
  }

  markSubscriptionCreated(): void {
    this.isSubscriptionCreated = true; // Set the flag to true
  }

  clearSubscriptionData(): void {

    const data = this.subscriptionData.getValue();
    if (data && !this.isSubscriptionCreated) { // Check data and subscription flag
      this.apiService.createCart(data).subscribe({
        next: (response) => {
          console.log('Cart created successfully');
        },
        error: (error) => {
          console.error('Error creating cart:', error);
        },
      });
    }
    localStorage.removeItem('subscriptionData');
    this.subscriptionData.next(null);
    this.isSubscriptionCreated = false; // Reset the flag for next usage
  }
  
}
